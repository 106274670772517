// src/pages/LeaseDetails/LeaseSummary.jsx

import React from 'react';
import { Box, Stack, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../common/constants/Color';
import Iconify from '../../components/iconify/Iconify';

export const LeaseSummary = ({ leaseData }) => {
  const { t } = useTranslation();

  if (!leaseData) {
    return null;
  }

  // Calculate some additional summary metrics
  const activeLeases = leaseData.leases
    ? leaseData.leases.filter((lease) => parseFloat(lease.totalAmount) > 0)
        .length
    : 0;
  const totalEarnings = leaseData.leases
    ? leaseData.leases
        .reduce((sum, lease) => sum + parseFloat(lease.earningsBalance), 0)
        .toFixed(8)
    : '0.00000000';
  const totalDailyReturns = leaseData.leases
    ? leaseData.leases
        .reduce((sum, lease) => sum + parseFloat(lease.dailyReturns), 0)
        .toFixed(8)
    : '0.00000000';
  const totalLeases = leaseData.leases ? leaseData.leases.length : 0;

  // Summary metric cards
  const metrics = [
    {
      title: t('Total Leased Gold'),
      value: `${parseFloat(leaseData.totalLeasedGold || 0).toFixed(2)} gm`,
      icon: 'mdi:gold',
      color: Colors.WARNING_MAIN,
      iconBg: Colors.WARNING_LIGHT,
    },
    {
      title: t('Total Profit'),
      value: `${parseFloat(leaseData.totalProfitOut || 0).toFixed(8)} gm`,
      icon: 'mdi:cash-multiple',
      color: Colors.SUCCESS_MAIN,
      iconBg: Colors.SUCCESS_LIGHT,
    },
    {
      title: t('Total Earnings'),
      value: `${totalEarnings} gm`,
      icon: 'mdi:chart-line',
      color: Colors.INFO_MAIN,
      iconBg: Colors.INFO_LIGHT,
    },
    {
      title: t('Daily Returns'),
      value: `${totalDailyReturns} gm`,
      icon: 'mdi:calendar-today',
      color: Colors.PRIMARY_MAIN,
      iconBg: Colors.PRIMARY_LIGHT,
    },
    {
      title: t('Active Leases'),
      value: activeLeases.toString(),
      icon: 'mdi:checkbox-marked-circle-outline',
      color: Colors.SUCCESS_MAIN,
      iconBg: Colors.SUCCESS_LIGHT,
    },
    {
      title: t('Total Leases'),
      value: totalLeases.toString(),
      icon: 'mdi:format-list-bulleted',
      color: Colors.SECONDARY_MAIN,
      iconBg: Colors.SECONDARY_LIGHT,
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={'27px'}
        sx={{ position: 'relative', bottom: '40px' }}
      >
        <Typography variant="h4" gutterBottom paddingTop={'7px'}>
          {t('LEASE_MANAGEMENT')}
        </Typography>
      </Stack>
      <Grid container spacing={3}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              sx={{
                p: 3,
                height: '100%',
                display: 'flex',
                borderRadius: 2,
                boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
                transition: 'all 0.3s',
                '&:hover': {
                  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                  transform: 'translateY(-5px)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 56,
                  height: 56,
                  borderRadius: '12px',
                  backgroundColor: metric.iconBg,
                  mr: 2,
                }}
              >
                <Iconify
                  icon={metric.icon}
                  width={32}
                  height={32}
                  color={metric.color}
                />
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  {metric.title}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {metric.value}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
