import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { Typography, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../../../common/constants/Color';
import { LanguageContext } from '../../../../globalContext/LanguageSwitcher';
import {
  successToaster,
  errorToaster,
  getModulePerm,
} from '../../../../utils/helperUtility';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';
import AuthInput from '../../../../components/auth-input';
import AuthInputMultiline from '../../../../components/auth-input';
import { styled } from '@mui/material/styles';
import { setBankDetails, getBankDetails } from '../../../../api/bankDetails';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../../auth/login/LoginForm.style';
import { bankDetailsSchema } from '../../../../schema/bankDetailsSchema';
import ENDPOINTS from '../../../../constants/endpoints';
import { MODULE_CONST } from '../../../../common/constants/ModuleConstant';
import BankDetailsCard from './BankDetailsCard';
import './BankDetailsForm.style.scss';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.RED}`,
  color: `${Colors.WHITE}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.RED}`,
  },
}));
const EditButton = styled(Button)(({ theme }) => ({
  background: `${Colors.DARK_GRAY}`,
  color: `${Colors.WHITE}`,
  fontSize: '14px',
  fontWeight: 500,
  marginTop: '24px',
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  width: 'fit-content',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.DARK_GRAY}`,
  },
}));
const FormContainer = styled('div')(() => {
  return {
    marginTop: '35px',
    height: '100%',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
    marginTop: '24px',
  };
});

export default function BankDetailsForm() {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isEditMode, setIsEditMode] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [formValues, setFormValues] = useState({
    iBanNumber: '',
    receiverName: '',
    bankName: '',
    instructions: ``,
    notes: ``,
    contactInfo: ``,
  });

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
    }));
  }, []);

  const getId = () => query.get('id');

  const [loading, setLoading] = useState(false);

  const isFieldError = (field) =>
    !!formik.touched?.[field] && !!formik.errors?.[field];

  const formik = useFormik({
    formValues: formValues,
    enableReinitialize: true,
    validationSchema: bankDetailsSchema,
    onSubmit: async (values) => {
      setFormValues(isEditMode ? values : formValues);
      submitEditBankDetails(values);
    },
  });

  const submitEditBankDetails = async (values) => {
    try {
      setLoading(true);
      let {
        iBanNumber,
        receiverName,
        bankName,
        instructions,
        notes,
        contactInfo,
      } = values;
      const payload = {
        iBanNumber,
        receiverName,
        bankName,
        instructions,
        notes,
        contactInfo,
      };
      const response = await setBankDetails(payload, getId());
      if (response.data.isSuccess) {
        successToaster(t('BANK_DETAILS_UPDATE_SUCCESS'), language);
        navigate(ENDPOINTS.BANK_DETAILS);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBankDetailsData();
  }, []);

  const getBankDetailsData = async (page = 1) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await getBankDetails();
      setDefaultValues(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank details data:', error);
      setLoading(false);
    }
  };
  const handleCancelButton = () => {
    formik.resetForm();
    navigate(ENDPOINTS.BANK_DETAILS);
    setIsEditMode(false);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    formik.setValues({
      iBanNumber: formik?.values?.iBanNumber,
      receiverName: formik?.values?.receiverName,
      bankName: formik?.values?.bankName,
      instructions: formik?.values?.instructions,
      notes: formik?.values?.notes,
      contactInfo: formik?.values?.contactInfo,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      formik.submitForm(e);
      getBankDetailsData();
      setIsEditMode(false);
    } else {
      return;
    }
  };

  const getDisableCond = () => formik.isValid;

  const currentModulePerm = getModulePerm(MODULE_CONST.BANK_DETAILS);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          {t('Bank Details')}
        </Typography>
      </Stack>
      {isEditMode ? (
        <FormContainer>
          <div className="row">
            <AuthInput
              className="col-12 col-md-12"
              htmlFor="iBanNumber"
              label={t('IBAN NUMBER')}
              disabled={!isEditMode}
              required={true}
              sx={textInputField(isFieldError('iBanNumber'), language)}
              inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
              placeholder={t('IBAN NUMBER')}
              name="iBanNumber"
              id="iBanNumber"
              onChange={formik.handleChange}
              value={formik.values.iBanNumber}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('iBanNumber')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={
                isFieldError('iBanNumber') && t(formik.errors.iBanNumber)
              }
            />
            <AuthInput
              className="col-12 col-md-12"
              htmlFor="receiverName"
              label={t('RECEIVER_NAME')}
              disabled={!isEditMode}
              required={true}
              sx={textInputField(isFieldError('receiverName'), language)}
              inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
              placeholder={t('RECEIVER_NAME')}
              name="receiverName"
              id="receiverName"
              onChange={formik.handleChange}
              value={formik.values.receiverName}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('receiverName')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={
                isFieldError('receiverName') && t(formik.errors.receiverName)
              }
            />
            <AuthInput
              className="col-12 col-md-12"
              htmlFor="bankName"
              label={t('Bank Name')}
              disabled={!isEditMode}
              required={true}
              sx={textInputField(isFieldError('bankName'), language)}
              inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
              placeholder={t('Bank Name')}
              name="bankName"
              id="bankName"
              onChange={formik.handleChange}
              value={formik.values.bankName}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('bankName')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={isFieldError('bankName') && t(formik.errors.bankName)}
            />
            <AuthInputMultiline
              className="col-12 col-md-12 textAreaWrapper"
              htmlFor="instructions"
              label={t('INSTRUCTIONS')}
              disabled={!isEditMode}
              required={true}
              multiline
              rows={4}
              sx={{ ...textInputField(isFieldError('instructions'), language) }}
              inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
              placeholder={t('INSTRUCTIONS')}
              name="instructions"
              id="instructions"
              onChange={formik.handleChange}
              value={formik.values.instructions}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('instructions')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={
                isFieldError('instructions') && t(formik.errors.instructions)
              }
            />
            <AuthInputMultiline
              className="col-12 col-md-12 textAreaWrapper"
              htmlFor="notes"
              label={t('NOTES')}
              disabled={!isEditMode}
              required={true}
              multiline
              rows={4}
              sx={{ ...textInputField(isFieldError('notes'), language) }}
              inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
              placeholder={t('NOTES')}
              name="notes"
              id="notes"
              onChange={formik.handleChange}
              value={formik.values.notes}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('notes')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={isFieldError('notes') && t(formik.errors.notes)}
            />
            <AuthInputMultiline
              className="col-12 col-md-12 textAreaWrapper"
              htmlFor="contactInfo"
              label={t('CONTACT_INFO')}
              disabled={!isEditMode}
              required={true}
              multiline
              rows={4}
              sx={{ ...textInputField(isFieldError('contactInfo'), language) }}
              inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
              placeholder={t('CONTACT_INFO')}
              name="contactInfo"
              id="contactInfo"
              onChange={formik.handleChange}
              value={formik.values.contactInfo}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('contactInfo')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={
                isFieldError('contactInfo') && t(formik.errors.contactInfo)
              }
            />
          </div>
          <FormButtons>
            {isEditMode && currentModulePerm.writeMode ? (
              <CancelButton onClick={() => handleCancelButton()}>
                {t('CANCEL')}
              </CancelButton>
            ) : null}

            {currentModulePerm.writeMode && isEditMode === true && (
              <SubmitButton
                type="submit"
                onClick={handleSubmit}
                disabled={!getDisableCond()}
              >
                {t('SAVE')}
              </SubmitButton>
            )}
          </FormButtons>
        </FormContainer>
      ) : (
        <BankDetailsCard BankDetailsData={defaultValues} />
      )}
      {!isEditMode && currentModulePerm.writeMode && (
        <EditButton onClick={() => handleEditClick()}>{t('EDIT')}</EditButton>
      )}
    </div>
  );
}
