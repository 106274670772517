import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useCallback, useContext, useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { getRoleList } from '../redux/slices/roleLists.slice';
import CommonTable from '../components/index-table/CommonTable';
import { userPageStyles } from './userPageStyle';
import { Badge } from '@shopify/polaris';
import Pagination from '../components/pagination';
import ENDPOINTS from '../constants/endpoints';
import DeleteModal from '../components/modal-components/delete-user-modal/DeleteModal';
import { deleteRole as deleteRoleApi } from '../api/roleList';
import {
  errorToaster,
  getModulePerm,
  successToaster,
} from '../utils/helperUtility';
import { LanguageContext } from '../globalContext/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import SearchBar from '../components/search-bar';
import { debounce } from 'lodash';
import { REGEX_PATTERN } from '../constants/regex';
import {
  CLICK_EVENT_KEYS,
  MODULE_CONST,
} from '../common/constants/ModuleConstant';
import { avatarStyle } from './RoleManagement.style';
// ---------------------------------------------------------------------

const MAX_LENGTH = 30;

const getDebouncedSearchFunction = (getRolesList) => {
  return debounce((searchTerm) => {
    getRolesList(searchTerm);
  }, 500);
};

export default function RolePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { language } = useContext(LanguageContext);

  const {
    data: rolesData,
    loading,
    totalList,
    limit,
  } = useSelector((state) => state.roleLists);

  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    userRole: '',
    roleId: '',
  });

  // For Pagination
  //========================================================
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const pageCount = Math.ceil(totalList / rowsPerPage);

  //========================================================
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getRolesList(searchTerm, 1, newRowsPerPage);
  };

  const getRolesList = useCallback(
    (searchValue = '', page = 1, pageSize = rowsPerPage) => {
      console.log(
        `Fetching roles: page=${page}, pageSize=${pageSize}, search=${searchValue}`
      );
      dispatch(
        getRoleList({
          reqData: {
            page_limit: pageSize,
            page_skip: page - 1,
            searchStr: searchValue,
          },
        })
      );
      setCurrentPage(page);
    },
    [dispatch, rowsPerPage]
  );

  useEffect(() => {
    getRolesList(searchTerm, currentPage, rowsPerPage);
  }, []);

  const handlePageChange = (event, value) => {
    console.log(`Page changed to: ${value}`);
    setCurrentPage(value);
    getRolesList(searchTerm, value, rowsPerPage);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const debouncedFetchAdminList = useCallback(
    getDebouncedSearchFunction((searchValue) =>
      getRolesList(searchValue, 1, rowsPerPage)
    ),
    [getRolesList, rowsPerPage]
  );

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    debouncedFetchAdminList(newSearchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    debouncedFetchAdminList('');
  };

  const permissions = getModulePerm(MODULE_CONST.ROLE_MANAGEMENT);

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
        {(permissions.readMode || permissions.writeMode) && (
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={'#A5A4A2'}
            display={'flex'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.VIEW, rowItemData)}
          >
            {t('VIEW_BTN')}
          </Typography>
        )}
        {permissions.writeMode && (
          <Box display={'flex'} gap={'16px'}>
            <Box
              onClick={() =>
                handleClickEvent(CLICK_EVENT_KEYS.EDIT, rowItemData)
              }
              title={t(
                rowItemData.systemDefinedRole
                  ? 'NOT_ALLOWED_FOR_SYSTEM_ADMIN'
                  : 'EDIT'
              )}
              sx={{
                cursor: rowItemData.systemDefinedRole
                  ? 'not-allowed'
                  : 'pointer',
              }}
            >
              <Iconify
                icon="streamline:pencil"
                width={'14px'}
                color="primary"
                sx={userPageStyles.penIconSx}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const transformedData = rolesData?.map((role) => {
    return {
      id: role.id,
      name: role.name || 'NA',
      description: role.description || 'N/A',
      assignedUsers: role.assignedUsers,
      renderActive: () => {
        return role.isActive ? (
          <Badge tone="enabled" progress="complete">
            {t('ACTIVE_BADGE')}
          </Badge>
        ) : (
          <Badge tone="disabled">{t('INACTIVE_BADGE')}</Badge>
        );
      },
      renderButton: () => <ButtonsForTable rowItemData={role} />,
    };
  });

  const roleColumns = [
    {
      key: 'name',
      title: t('TH_ROLE_NAME'),
      content: (rowData) => <strong>{rowData.name}</strong>,
    },
    { key: 'description', title: t('TH_ROLE_DESC') },
    { key: 'assignedUsers', title: t('TH_ASSIGNED_USERS') },
    {
      key: 'renderActive',
      title: t('TH_STATUS'),
      isComponent: true,
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const resourceName = {
    singular: 'role',
    plural: 'roles',
  };

  const deleteRole = async (roleId) => {
    const roleData = {
      isActive: false,
    };
    try {
      const response = await deleteRoleApi(roleId, roleData);
      if (response?.data?.isSuccess) {
        getRolesList(searchTerm, currentPage, rowsPerPage);
        successToaster(t('ROLES_DELETE_MSG'), language);
      }
    } catch (error) {
      errorToaster(t('SOME_ERROR_OCCOURED'), language);
    }
  };

  const handleConfirmDeleteClick = () => {
    deleteRole(userDetails.roleId);
    setOpen(false);
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_ROLE:
        navigate(ENDPOINTS.ROLE_FORM);
        break;
      case CLICK_EVENT_KEYS.EDIT:
        if (data.systemDefinedRole) {
          return;
        }
        navigate({
          pathname: ENDPOINTS.ROLE_FORM,
          search: `?roleId=${data.id.toString()}`,
        });
        break;
      case CLICK_EVENT_KEYS.DELETE:
        if (data.systemDefinedRole) {
          return;
        }
        setOpen(true);
        setUserDetails((prev) => ({
          ...prev,
          userRole: data.name,
        }));
        setUserDetails((prev) => ({
          ...prev,
          roleId: data.id,
        }));
        break;
      case CLICK_EVENT_KEYS.VIEW:
        navigate({
          pathname: ENDPOINTS.ROLE_FORM,
          search: `?roleId=${data.id.toString()}&view=true`,
        });
    }
  };

  return (
    <Box>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'27px'}
        >
          <Typography variant="h4" gutterBottom paddingTop={'7px'}>
            {t('ROLE_MANAGEMENT')}
          </Typography>
          {permissions.writeMode && (
            <Button
              variant="contained"
              sx={userPageStyles.buttonSx}
              onClick={() => handleClickEvent(CLICK_EVENT_KEYS.NEW_ROLE)}
              startIcon={
                <Iconify width={'18px'} icon="icon-park-solid:add-one" />
              }
            >
              {t('ADD_NEW_ROLE')}
            </Button>
          )}
        </Stack>

        <Card sx={{ boxShadow: 'none' }}>
          <Scrollbar>
            <Card>
              <Box padding="8px" borderBottom="1px solid #E3E3E3">
                <Stack direction={'row'} justifyContent={'end'} gap={'10px'}>
                  <SearchBar
                    onChange={handleSearchChange}
                    value={searchTerm}
                    onClear={handleClearSearch}
                    placeholder={t('Search')}
                    maxlength={MAX_LENGTH}
                  />
                </Stack>
              </Box>
              <CommonTable
                resourceName={resourceName}
                data={transformedData}
                columns={roleColumns}
                isCheckBox={false}
                loading={loading}
                showPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalList={totalList}
                itemsPerPage={rowsPerPage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50, 100]}
                emptyTableText={t('DATA_NOT_EXIST_ON_TABLE')}
                onSortButtonClick={() => {}}
              />
            </Card>
          </Scrollbar>
        </Card>
      </Container>
      <DeleteModal
        isopen={open}
        handleClose={handleModalClose}
        toCloseOnOutsideModalClick={true}
        handleConfirmDeleteClick={handleConfirmDeleteClick}
      >
        <Box marginTop="0px">
          <Box display="flex" gap="16px" marginTop="16px">
            <Box
              height={'56px'}
              width={'56px'}
              borderRadius={'50%'}
              bgcolor={'lightGray'}
            >
              <Avatar sx={avatarStyle}>{userDetails.userRole[0] ?? 'R'}</Avatar>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography sx={userPageStyles.roleTextSx}>
                {userDetails.userRole}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DeleteModal>
    </Box>
  );
}
