import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export function getRewardsTransactions(userId) {
  return apiRequest({
    endpoint: API_ENDPOINTS.REWARDS_TRANSACTIONS,
    method: METHODS.GET,
    params: { userId },
    privateURL: true,
  });
}
