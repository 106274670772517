import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import { Badge } from '@shopify/polaris';
import moment from 'moment';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar-debounce';
import { getadminList } from '../../api/adminManagement';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import SelectInput from '../../components/select-input';
import { statusvalue } from './accountManagerConstant';
import { role } from '../../constants/role-constant';
import DateRangePicker from '../../components/date-range-picker';
import { Colors } from '../../common/constants/Color';
import { getModulePerm, getFormattedDate } from '../../utils/helperUtility';
import { MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';

const CLICK_EVENT_KEYS = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  VIEW: 'VIEW',
  NEW_ACCOUNT_MANAGER: 'NEW_ACCOUNT_MANAGER',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function AccountManager() {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [accountManager, setAccountManagerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(true);
  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(10);
  const pageCount = Math.ceil(totalList / itemsPerPage);

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value, rowsPerPage);
  };

  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
    status: 'All',
  });

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  const getListQueryPayload = () => {
    let dateFilterData = {};
    if (searchTerm) {
      dateFilterData['searchStr'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['startDate'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['endDate'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    return dateFilterData;
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, filterData]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();

      if (filterData.status !== statusvalue.ALL) {
        if (filterData.status == statusvalue.active) {
          queryPayload.status = true;
        } else {
          if (filterData.status == statusvalue.inActive) {
            queryPayload.status = false;
          }
        }
      }
      const { data } = await getadminList({
        ...queryPayload,
        role: role.ACCOUNT_MANAGER,
        pageLimit: pageSize,
        pageSkip: page - 1,
      });
      setAccountManagerData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  const currentModulePerm = getModulePerm('ACCOUNT_MANAGER');

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={Colors.GRAY}
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.VIEW, rowItemData)}
          >
            {t('VIEW_BTN')}
          </Typography>
          {currentModulePerm.writeMode && (
            <Box display={'flex'} gap={'16px'}>
              <Box>
                <Iconify
                  icon="streamline:pencil"
                  width={'14px'}
                  color="primary"
                  sx={{
                    ...userPageStyles.penIconSx,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleClickEvent(CLICK_EVENT_KEYS.EDIT, rowItemData)
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const currentItems = accountManager.map((item) => ({
    id: item.id,
    name:
      item.firstName || item.middleName || item.lastName
        ? `${item.firstName || ''} ${item.middleName || ''} ${
            item.lastName || ''
          }`.trim()
        : 'NA',
    email: item.email || '',
    phone: item.mobileCode
      ? `(+${item.mobileCode}) ${item.phoneNo}`
      : item.phoneNo,
    createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY, HH:mm'),
    renderActive: () => {
      return item.isActive ? (
        <Badge tone="enabled" progress="complete">
          {t('ACTIVE_BADGE')}
        </Badge>
      ) : (
        <Badge tone="disabled">{t('INACTIVE_BADGE')}</Badge>
      );
    },
    renderButton: () => <ButtonsForTable rowItemData={item} />,
  }));

  const roleColumns = [
    {
      key: 'name',
      title: t('TH_NAME'),
      content: (rowData) => <strong>{rowData.name}</strong>,
      sortButton: false,
    },
    { key: 'phone', title: t('PHONE_NUMBER') },
    {
      key: 'email',
      title: t('TH_AD_EMAIL'),
      sortButton: false,
      content: (rowData) => rowData.email,
    },

    { key: 'createdAt', title: t('TH_CREATION_DATE'), sortButton: true },
    {
      key: 'renderActive',
      title: t('TH_STATUS'),
      sortButton: true,
      isComponent: true,
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_ACCOUNT_MANAGER:
        navigate(ENDPOINTS.ACCOUNT_MANAGER_FORM);
        break;
      case CLICK_EVENT_KEYS.EDIT:
        navigate(`${ENDPOINTS.ACCOUNT_MANAGER_FORM}?id=${data.id}`);
        break;
      case CLICK_EVENT_KEYS.VIEW:
        navigate(
          `${ENDPOINTS.ACCOUNT_MANAGER_FORM}?id=${data.id}&view=${true}`
        );
        break;
    }
  };

  useEffect(() => {
    const savedStatus = localStorage.getItem('accMngStatus');
    const savedStartDate = localStorage.getItem('accMngStartDate');
    if (savedStatus) {
      setFilterData((prevData) => ({
        ...prevData,
        status: savedStatus,
      }));
    }
    if (savedStartDate) {
      setFilterData((prevData) => ({
        ...prevData,
        createdAt: savedStartDate,
      }));
    }
  }, []);

  const handleFilterSelection = (key, value) => {
    setFilterData((prevData) => {
      const newData = { ...prevData, [key]: value };
      if (key === 'status') {
        localStorage.setItem('accMngStatus', value);
      }
      if (key === 'createdAt') {
        localStorage.setItem('accMngStartDate', value[0]);
      }
      return newData;
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('ACCOUNT_MANAGER')}
        </Typography>

        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() =>
              handleClickEvent(CLICK_EVENT_KEYS.NEW_ACCOUNT_MANAGER)
            }
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_NEW_ACCOUNT_MANAGER')}
          </Button>
        )}
      </Stack>

      <Card>
        <StyledFilterBox>
          <div className="w-50 d-flex justify-content-start">
            <SelectInput
              placeholder={t('Status')}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={t('Status')}
              value={filterData.status}
              options={[
                { label: t('ALL'), value: 'All' },
                { label: t('ACTIVE'), value: 'active' },
                { label: t('INACTIVE'), value: 'inActive' },
              ]}
              handleSelectChange={(event) => {
                handleFilterSelection('status', event);
              }}
            />
            <div className="ms-2">
              <DateRangePicker
                placeholder={t('DATE_PICKER_CREATION_DATE_TITLE')}
                dateRange={filterData.createdAt}
                setDateRange={(event) =>
                  handleFilterSelection('createdAt', event)
                }
              />
            </div>
          </div>
          <SearchBar
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={t('Search')}
            maxlength={MAX_INPUT_LENGTH}
            onClear={handleClearSearch}
          />
        </StyledFilterBox>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={roleColumns}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          handleStatusChange={handleStatusChange}
          showPagination={true}
          pageCount={pageCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalList={totalList}
          itemsPerPage={itemsPerPage}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          emptyTableText={t('NO_ACCOUNT_MANAGER_FOUND')}
        />
      </Card>
    </Container>
  );
}
