import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const BankDetailsCard = ({ BankDetailsData }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="body1" paragraph>
          <strong>IBAN Number:</strong>
          <br /> {BankDetailsData.iBanNumber}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Receiver Name:</strong>
          <br /> {BankDetailsData.receiverName}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Bank Name:</strong>
          <br /> {BankDetailsData.bankName}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Instructions:</strong>
          <br /> {BankDetailsData.instructions}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Notes:</strong>
          <br /> {BankDetailsData.notes}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Contact Info:</strong>
          <br /> {BankDetailsData.contactInfo}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BankDetailsCard;
