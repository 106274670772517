import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import { Badge } from '@shopify/polaris';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from './../userPageStyle';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar';
import { getProductList } from '../../api/product';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../components/select-input';
import './../AdminManagement/adminConstant';
import './../AdminManagement/adminManagement.css';
import {
  changeLabelTranslation,
  getModulePerm,
} from '../../utils/helperUtility';
import {
  MAX_INPUT_LENGTH,
  METAL_TYPE_OPTIONS,
} from '../../common/constants/AppConstants';
import { ALL } from './../../common/constants/AppConstants';
import { Colors } from './../../common/constants/Color';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import BulkUpload from '../../pages/bulk-upload/bulk-upload';
const CLICK_EVENT_KEYS = {
  NEW_PRODUCT: 'NEW_PRODUCT',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

function extractValuesIfNotTypeIsAll(object, key) {
  if (key === 'status') {
    const val = object[key];
    if (val === 'ACTIVE') return true;
    else if (val === 'INACTIVE') return false;
    return undefined;
  }
  return object[key] === ALL ? undefined : object[key];
}

export default function ProductManagementPage() {
  const permissions = getModulePerm('PRODUCT_MANAGEMENT');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(true);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState({
    category: ALL,
    metalType: ALL,
    purity: ALL,
    status: ALL,
  });
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const metalList = changeLabelTranslation(METAL_TYPE_OPTIONS, t);
  const itemsPerPage = parseInt(limit);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value, rowsPerPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, searchFilter]);

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const { data } = await getProductList({
        pageLimit: pageSize,
        pageSkip: page - 1,
        searchStr: searchTerm ? searchTerm : undefined,
        purity: extractValuesIfNotTypeIsAll(searchFilter, 'purity'),
        status: extractValuesIfNotTypeIsAll(searchFilter, 'status'),
        category: extractValuesIfNotTypeIsAll(searchFilter, 'category'),
        metalType: extractValuesIfNotTypeIsAll(searchFilter, 'metalType'),
      });
      setProductData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product data:', error);
      setLoading(false);
    }
  };

  const ButtonsForTable = ({ product }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          {(permissions.readMode || permissions.writeMode) && (
            <Typography
              fontWeight={'500'}
              fontSize={'12px'}
              fontFamily={'Inter'}
              color={Colors.GRAY}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickEvent(CLICK_EVENT_KEYS.VIEW, product)}
            >
              {t('VIEW_BTN')}
            </Typography>
          )}
          <Box display={'flex'} gap={'16px'}>
            {permissions.writeMode && (
              <Box>
                <Iconify
                  className
                  icon="streamline:pencil"
                  width={'14px'}
                  color="primary"
                  sx={{
                    ...userPageStyles.penIconSx,
                    cursor: currentModulePerm.writeMode
                      ? 'pointer'
                      : 'not-allowed',
                  }}
                  onClick={() =>
                    currentModulePerm.writeMode &&
                    handleClickEvent(CLICK_EVENT_KEYS.EDIT, product)
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  };

  const currentItems = productData.map((product) => ({
    ...product,
    category: product.category ?? 'N/A',
    quantity: <Box>{product.quantity}</Box>,
    renderActive: () => {
      return product.isActive ? (
        <Badge tone="enabled" progress="complete">
          {t('ACTIVE_BADGE')}
        </Badge>
      ) : (
        <Badge tone="disabled">{t('INACTIVE_BADGE')}</Badge>
      );
    },
    buttons: <ButtonsForTable product={product} />,
  }));

  const roleColumns = [
    {
      key: 'name',
      title: t('Product Name'),
    },
    {
      key: 'sku',
      title: t('Product ID'),
    },
    {
      key: 'weight',
      title: t('Weight'),
    },
    {
      key: 'category',
      title: t('Category'),
    },
    {
      key: 'metalType',
      title: t('Metal Type'),
    },
    {
      key: 'purity',
      title: t('Purity'),
    },
    {
      key: 'quantity',
      title: t('Stock Level'),
    },
    {
      key: 'renderActive',
      title: t('TH_STATUS'),
      isComponent: true,
    },
    {
      key: 'buttons',
      title: '',
    },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_PRODUCT:
        navigate(ENDPOINTS.ADDPRODUCT);
        break;
      case CLICK_EVENT_KEYS.EDIT:
        navigate(`${ENDPOINTS.ADDPRODUCT}?productId=${data.id}`);
        break;
      case CLICK_EVENT_KEYS.VIEW:
        navigate(`${ENDPOINTS.ADDPRODUCT}?productId=${data.id}&view=${true}`);
        break;
    }
  };

  const currentModulePerm = getModulePerm('PRODUCT_MANAGEMENT');

  const handleFilterSelection = (key, value) => {
    setSearchFilter({
      ...searchFilter,
      [key]: value,
    });
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
    getTableData();
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('Product Management')}
        </Typography>
        {permissions.writeMode && (
          <Box>
            <Button
              variant="contained"
              sx={userPageStyles.buttonSx}
              onClick={() => {
                setOpen(true);
              }}
              startIcon={
                <Iconify width={'18px'} icon="material-symbols:upload" />
              }
            >
              {t('Bulk Update')}
            </Button>{' '}
            {}
            <Button
              variant="contained"
              sx={userPageStyles.buttonSx}
              onClick={() => handleClickEvent(CLICK_EVENT_KEYS.NEW_PRODUCT)}
              startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
            >
              {t('Add Product')}
            </Button>
          </Box>
        )}
      </Stack>

      <Card>
        <Scrollbar>
          <div className="filter-wrapper">
            <Stack direction={'row'} flexGrow={1} columnGap={'18px'}>
              <SelectInput
                placeholder={t('Category')}
                labelInline={true}
                containerSx={{ width: '120px' }}
                label={'category'}
                value={searchFilter.category}
                options={[
                  { label: t('ALL'), value: ALL },
                  { label: t('BAR'), value: 'BAR' },
                  { label: t('COINS'), value: 'COINS' },
                  { label: t('EARRINGS'), value: 'EARRINGS' },
                  { label: t('NECKLACES'), value: 'NECKLACES' },
                  { label: t('BANGLES'), value: 'BANGLES' },
                  { label: t('RINGS_TITLE'), value: 'RINGS' },
                ]}
                handleSelectChange={(event) => {
                  handleFilterSelection('category', event);
                }}
              />
              <SelectInput
                placeholder={t('Metal Type')}
                labelInline={true}
                containerSx={{ width: '120px' }}
                label={'MetalType'}
                value={searchFilter.metalType}
                options={metalList}
                handleSelectChange={(event) => {
                  handleFilterSelection('metalType', event);
                }}
              />
              <SelectInput
                placeholder={t('Purity')}
                labelInline={true}
                containerSx={{ width: '120px' }}
                label={'Purity'}
                value={searchFilter.purity}
                options={[
                  { label: t('ALL'), value: ALL },
                  { label: t('14K'), value: '14k' },
                  { label: t('18K'), value: '18k' },
                  { label: t('22K'), value: '22k' },
                  { label: t('24K'), value: '24k' },
                ]}
                handleSelectChange={(event) => {
                  handleFilterSelection('purity', event);
                }}
              />
              <SelectInput
                placeholder={t('TH_STATUS')}
                labelInline={true}
                containerSx={{ width: '120px' }}
                label={'STATUS'}
                value={searchFilter.status}
                options={[
                  { label: t('ALL'), value: ALL },
                  { label: t('ACTIVE'), value: 'ACTIVE' },
                  { label: t('INACTIVE'), value: 'INACTIVE' },
                ]}
                handleSelectChange={(event) => {
                  handleFilterSelection('status', event);
                }}
              />
            </Stack>
            <SearchBar
              onChange={handleSearchChange}
              value={searchTerm}
              placeholder={t('Search')}
              maxlength={MAX_INPUT_LENGTH}
              onClear={handleClearSearch}
            />
          </div>
          <CommonTable
            resourceName={resourceName}
            data={currentItems}
            columns={roleColumns}
            isCheckBox={false}
            loading={loading}
            showPagination={true}
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalList={totalList}
            itemsPerPage={itemsPerPage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onSortButtonClick={() => {}}
            handleStatusChange={handleStatusChange}
            emptyTableText={t('NO_PRODUCT_FOUND')}
          />
        </Scrollbar>
      </Card>
      {open && (
        <BulkUpload
          open={open}
          handleClose={handleClose}
          handleAccept={() => {}}
          handleReject={() => {}}
          title={t('BULK_UPLOAD')}
          productData={productData}
        />
      )}
    </Container>
  );
}
