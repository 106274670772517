import {
  HomeIcon,
  CollectionFilledIcon,
  OrderFilledIcon,
  PersonFilledIcon,
  WalletFilledIcon,
  BankFilledIcon,
  ImageIcon,
  FileIcon,
  ReceiptDollarFilledIcon,
  InventoryFilledIcon,
  CartIcon,
  OrderIcon,
  GiftCardIcon,
  WrenchIcon,
  TargetFilledIcon,
  MoneyFilledIcon,
} from '@shopify/polaris-icons';
import ENDPOINTS from '../../../constants/endpoints';
import { MODULE_CONST } from '../../../common/constants/ModuleConstant';

const createConfig = (handleNavigation, selectedItem) => {
  const userPermissions = JSON.parse(localStorage.getItem('userDetails')) || {};
  const navModules = [
    {
      excludeurls: ['#'],
      label: 'HOME',
      moduleCode: 'DASHBOARD',
      icon: HomeIcon,
      onClick: handleNavigation(ENDPOINTS.HOME),
      selected: 'HOME' === selectedItem,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.ADMIN_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ADMIN_MANAGEMENT),
      label: 'ADMIN_MANAGEMENT',
      icon: CollectionFilledIcon,
      moduleCode: 'ADMIN_MANAGEMENT',
    },
    {
      excludeurls: ['#'],
      label: 'BANK_DETAILS',
      moduleCode: 'BANK_DETAILS',
      superAdminAccess: true,
      selected: MODULE_CONST.BANK_DETAILS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.BANK_DETAILS),
      icon: WalletFilledIcon,
    },
    {
      excludeurls: ['#'],
      label: 'ACCOUNT_MANAGEMENT',
      moduleCode: 'ACCOUNT_MANAGER',
      selected: MODULE_CONST.ACCOUNT_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ACCOUNT_MANAGEMENT),
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.USER_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.USER_MANAGEMENT),
      label: 'USER_MANAGEMENT',
      icon: OrderFilledIcon,
      moduleCode: 'USER_MANAGEMENT',
    },
    {
      selected: MODULE_CONST.ROLE_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ROLE_MANAGEMENT),
      superAdminAccess: true,
      excludeurls: ['#'],
      label: 'ROLE_MANAGEMENT',
      moduleCode: 'ROLE_MANAGEMENT',
      icon: OrderFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.TRANSACTION_HISTORY === selectedItem,
      onClick: handleNavigation(ENDPOINTS.TRANSACTION_MANAGEMENT),
      label: 'TRANSACTIONS',
      icon: ReceiptDollarFilledIcon,
      moduleCode: 'TRANSACTION_HISTORY',
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.LEASE === selectedItem,
      onClick: handleNavigation(ENDPOINTS.LEASE),
      label: 'LEASE_MANAGEMENT',
      icon: MoneyFilledIcon,
      moduleCode: 'LEASE',
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.VERIFICATION_APPROVAL === selectedItem,
      onClick: handleNavigation(ENDPOINTS.KYC_MANAGEMENT),
      label: 'VERIFICATION_APPROVAL',
      icon: FileIcon,
      moduleCode: 'VERIFICATION_APPROVAL',
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.VERIFICATION_APPROVAL_KYB === selectedItem,
      onClick: handleNavigation(ENDPOINTS.KYB_MANAGEMENT),
      label: 'VERIFICATION_APPROVAL_KYB',
      icon: FileIcon,
      moduleCode: 'VERIFICATION_APPROVAL_KYB',
    },
    {
      excludeurls: ['#'],
      label: 'PRODUCT_MANAGEMENT',
      moduleCode: 'PRODUCT_MANAGEMENT',
      selected: MODULE_CONST.PRODUCT_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.PRODUCT_MANAGEMENT),
      icon: CartIcon,
    },
    /* Other menu items... */
    {
      excludeurls: ['#'],
      label: 'ORDER_MANAGEMENT',
      moduleCode: 'ORDER_MANAGEMENT',
      selected: MODULE_CONST.ORDER_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ORDER_MANAGEMENT),
      icon: OrderIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.WAREHOUSE_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.WAREHOUSE_MANAGEMENT),
      label: 'WAREHOUSE_MANAGEMENT',
      moduleCode: 'WAREHOUSE_MANAGEMENT',
      icon: InventoryFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.ACCOUNT_DELETE_REQUEST === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ACCOUNT_LIST),
      label: 'ACCOUNT_DELETE_REQUEST',
      moduleCode: MODULE_CONST.USER_MANAGEMENT,
      icon: PersonFilledIcon,
    },
    {
      excludeurls: ['#'],
      label: 'Report Management',
      moduleCode: 'REPORTING_ANALYTICS',
      selected: MODULE_CONST.REPORTING_ANALYTICS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.REPORT_MANAGEMENT),
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      label: 'ADMIN_LOGS_MANAGEMENT',
      moduleCode: 'ADMIN_LOGS_MANAGEMENT',
      superAdminAccess: true,
      selected: MODULE_CONST.ADMIN_LOGS_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ADMIN_LOGS),
      icon: WrenchIcon,
    },
    {
      excludeurls: ['#'],
      label: 'USER_GOALS',
      moduleCode: 'USER_GOALS',
      superAdminAccess: true,
      selected: MODULE_CONST.USER_GOALS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.USER_GOALS),
      icon: TargetFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.TRANSFER_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.TRANSFER_LIST),
      label: 'TRANSFER_GOLD_SILVER',
      moduleCode: MODULE_CONST.TRANSFER_MANAGEMENT,
      icon: PersonFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.ANNOUNCEMENT_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ANNOUNCEMENT_LIST),
      label: 'ANNOUNCEMENT_MANAGEMENT',
      moduleCode: MODULE_CONST.ANNOUNCEMENT_MANAGEMENT,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.TICKET_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.TICKET_MANAGEMENT),
      label: 'TICKET_MANAGEMENT',
      moduleCode: MODULE_CONST.TICKET_MANAGEMENT,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.WITHDRAWALS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.WITHDRAWALS),
      label: 'WITHDRAWALS',
      moduleCode: MODULE_CONST.WITHDRAWALS,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.BANK_TRANSFERS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.BANK_TRANSFERS),
      label: 'BANK_TRANSFERS',
      moduleCode: MODULE_CONST.BANK_TRANSFERS,
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.CITY_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.CITY_MANAGEMENT),
      label: 'CITY_MANAGEMENT',
      moduleCode: MODULE_CONST.CITY_MANAGEMENT,
      icon: ImageIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.GIFT_SETTINGS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.GIFT_SETTINGS),
      label: 'GIFT_SETTINGS',
      moduleCode: MODULE_CONST.GIFT_SETTINGS,
      icon: GiftCardIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.THIRD_PARTY_CLIENTS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.THIRD_PARTY_CLIENTS_LISTING),
      label: 'THIRD_PARTY_CLIENTS',
      icon: OrderFilledIcon,
      moduleCode: 'THIRD_PARTY_CLIENTS',
    },
  ];

  if (userPermissions.systemDefinedRole) {
    return navModules;
  }

  return navModules.filter((navItem) => {
    // For parent items with subitems, check if any child has permission
    if (navItem.subNavigationItems) {
      const anyChildHasPermission = navItem.subNavigationItems.some((child) => {
        const childModulePermission =
          userPermissions.permissions?.find(
            (userPerm) => userPerm.moduleCode === child.moduleCode
          ) || {};
        return (
          childModulePermission?.readMode || childModulePermission?.writeMode
        );
      });

      if (anyChildHasPermission) return true;
    }

    if (navItem.moduleCode === 'HOME') return true;

    const modulePermission =
      userPermissions.permissions?.find(
        (userPerm) => userPerm.moduleCode === navItem.moduleCode
      ) || {};

    return (
      (navItem.isSuperAdminAccess && userPermissions.systemDefinedRole) ||
      modulePermission?.readMode ||
      modulePermission?.writeMode
    );
  });
};

export default createConfig;
