import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  TextField,
  InputAdornment,
  Grid,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Pagination as MUIPagination,
  PaginationItem,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../common/constants/Color';
import { NA } from '../../common/constants/AppConstants';
import Iconify from '../../components/iconify/Iconify';
import { getUser } from '../../api/getUser';

// Custom Pagination component
const Pagination = ({ page, count, onChange }) => {
  return (
    <MUIPagination
      count={count}
      page={page}
      onChange={onChange}
      renderItem={(item) => {
        return <PaginationItem {...item} />;
      }}
    />
  );
};

export const LeaseInformation = ({
  leaseData,
  isLoading,
  onPaginationChange,
  currentPage,
  rowsPerPage,
  totalLeases,
  forceTotal = false,
}) => {
  const { t } = useTranslation();
  // Store user names by their userId
  const [userMap, setUserMap] = useState({});
  const [userLoading, setUserLoading] = useState(true);

  // Use the currentPage passed from parent instead of maintaining local state
  const page = currentPage || 1;

  // Search state - simplified to fix typing issue
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [filteredLeases, setFilteredLeases] = useState([]);

  useEffect(() => {
    const fetchUserNames = async () => {
      if (!leaseData?.leases?.length) {
        setUserLoading(false);
        return;
      }

      setUserLoading(true);

      try {
        // Get all unique user IDs
        const userIds = [
          ...new Set(
            leaseData.leases.map((lease) => lease.fkUsersId).filter(Boolean)
          ),
        ];
        // Create a map of user IDs to names
        const userNames = {};

        // Fetch user details for each unique user ID
        const userPromises = userIds.map(async (userId) => {
          try {
            const userDetails = await getUser(userId);

            // Construct full name
            const fullName =
              [
                userDetails?.data?.data?.firstName || '',
                userDetails?.data?.data?.lastName || '',
              ]
                .filter(Boolean)
                .join(' ')
                .trim() || 'Unknown User';

            // Store the user's name with their ID as the key
            userNames[userId] = fullName;
          } catch (error) {
            userNames[userId] = 'Unknown User';
          }
        });

        // Wait for all user fetches to complete
        await Promise.all(userPromises);

        setUserMap(userNames);
      } catch (error) {
        console.error('Error in fetchUserNames:', error);
      } finally {
        setUserLoading(false);
      }
    };

    fetchUserNames();
  }, [leaseData]);

  // Handle search input change - simplified to fix typing issue
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    // Simple search function
    if (value.trim() === '') {
      setIsSearching(false);
      setFilteredLeases([]);
    } else {
      setIsSearching(true);
      if (leaseData && leaseData.leases) {
        const filtered = leaseData.leases.filter((lease) => {
          const searchTerm = value.toLowerCase();
          const userName = userMap[lease.fkUsersId] || '';

          // Check user name
          if (userName.toLowerCase().includes(searchTerm)) {
            return true;
          }

          // Check gold amount
          const goldAmount = String(lease.totalAmount || '0');
          if (goldAmount.includes(searchTerm)) {
            return true;
          }

          // Check profit power
          const profitPower = String(lease.profitPower || '0');
          if (profitPower.includes(searchTerm)) {
            return true;
          }

          // Check premium status
          if (searchTerm === 'premium' && lease.isPremium) {
            return true;
          }

          if (searchTerm === 'standard' && !lease.isPremium) {
            return true;
          }

          return false;
        });

        setFilteredLeases(filtered);
      }
    }

    // Reset to first page when search changes
    onPaginationChange(1, rowsPerPage);
  };

  // Helper function to get user name from user ID
  const getUserName = (userId) => {
    if (!userId) return 'Unknown User';
    return userMap[userId] || 'Unknown User';
  };

  // Helper function to format dates
  const formatDate = (dateString) => {
    if (!dateString) return NA;

    try {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } catch (error) {
      return NA;
    }
  };

  // Handle page change with server-side pagination
  const handleChangePage = (event, newPage) => {
    if (!isSearching) {
      // Trigger parent component to fetch new data with updated pagination
      onPaginationChange(newPage, rowsPerPage);
    } else {
      // For client-side search, we handle pagination locally
      onPaginationChange(newPage, rowsPerPage);
    }
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    // Always reset to first page when changing rows per page
    onPaginationChange(1, newRowsPerPage);
  };

  if (!leaseData || !leaseData.leases) {
    return (
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" align="center">
          {isLoading ? (
            <CircularProgress size={24} sx={{ mr: 1 }} />
          ) : (
            t('No lease details available')
          )}
        </Typography>
      </Paper>
    );
  }

  // Use either the filtered data for search or the server-provided data
  const displayLeases = isSearching
    ? filteredLeases.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    : leaseData.leases;

  // Calculate total count for pagination
  const totalCount = isSearching
    ? filteredLeases.length
    : forceTotal
    ? totalLeases
    : leaseData.leases.length;

  return (
    <Paper sx={{ mt: 4, p: 0, overflow: 'hidden' }}>
      {/* Header with User Details and Summary */}
      <Box
        sx={{
          p: 3,
          borderBottom: `1px solid ${Colors.LIGHT_GRAY}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {t('Gold Lease Details')}
          </Typography>
        </Box>
      </Box>

      {/* Search and Filter Controls */}
      <Box sx={{ p: 2, borderBottom: `1px solid ${Colors.LIGHT_GRAY}` }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            {/* Simplified search field to fix typing issue */}
            <TextField
              fullWidth
              placeholder={t('Search by user, amount, or status...')}
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled' }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body2"
              sx={{ textAlign: { xs: 'left', md: 'right' } }}
            >
              {isSearching
                ? t('Showing {{filtered}} out of {{total}} leases', {
                    filtered: filteredLeases.length,
                    total: forceTotal ? totalLeases : leaseData.leases.length,
                  })
                : t('Total leases: {{count}}', {
                    count: forceTotal ? totalLeases : leaseData.leases.length,
                  })}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.LIGHT_GRAY }}>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('User')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {t('Start Date')}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {t('Gold Amount')}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {t('Earnings Balance')}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {t('Daily Returns')}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {t('Profit Power')}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {t('Next Payout')}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                {t('Premium')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} align="center" sx={{ py: 4 }}>
                  <CircularProgress size={32} />
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {t('Loading lease data...')}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : displayLeases.length > 0 ? (
              displayLeases.map((lease) => (
                <TableRow key={lease.id || lease._id} hover>
                  <TableCell>
                    {userLoading ? 'Loading...' : getUserName(lease.fkUsersId)}
                  </TableCell>
                  <TableCell>{formatDate(lease.leaseStartDate)}</TableCell>
                  <TableCell align="right">
                    {parseFloat(lease.totalAmount || '0').toFixed(2)} gm
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(lease.earningsBalance || '0').toFixed(8)} gm
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(lease.dailyReturns || '0').toFixed(8)} gm
                  </TableCell>
                  <TableCell align="right">{lease.profitPower || 0}x</TableCell>
                  <TableCell align="right">
                    {formatDate(lease.nextPayoutDate)}
                  </TableCell>
                  <TableCell align="center">
                    {lease.isPremium ? (
                      <Chip
                        icon={
                          <Iconify
                            icon="mdi:crown"
                            color={Colors.WARNING_MAIN}
                          />
                        }
                        label={t('Premium')}
                        size="small"
                        sx={{
                          bgcolor: `${Colors.WARNING_LIGHT}`,
                          color: Colors.WARNING_MAIN,
                          fontWeight: 'bold',
                        }}
                      />
                    ) : (
                      <Chip
                        label={t('Standard')}
                        size="small"
                        sx={{ bgcolor: Colors.LIGHT_GRAY }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {searchQuery
                    ? t('No leases matching your search criteria')
                    : t('No lease details available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderTop: `1px solid ${Colors.LIGHT_GRAY}`,
        }}
      >
        {/* Rows per page selector */}
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="rows-per-page-label">{t('Rows per page')}</InputLabel>
          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            label={t('Rows per page')}
            disabled={isLoading}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
          </Select>
        </FormControl>

        {/* Page numbers */}
        <Pagination
          page={page}
          count={Math.max(1, Math.ceil(totalCount / rowsPerPage))}
          onChange={handleChangePage}
        />

        {/* Display range */}
        <Typography variant="body2" color="text.secondary">
          {t('Showing')} {Math.min((page - 1) * rowsPerPage + 1, totalCount)}-
          {Math.min(page * rowsPerPage, totalCount)} {t('of')} {totalCount}
        </Typography>
      </Box>

      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {t('Last updated')}: {formatDate(new Date().toISOString())}
        </Typography>
      </Box>
    </Paper>
  );
};

export default LeaseInformation;
