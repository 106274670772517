import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import ENDPOINTS from '../../constants/endpoints';
import tradeService from '../../api/trades';
import './TradeList.css';
import { Colors } from '../../common/constants/Color';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
import { getFormattedDate } from '../../utils/helperUtility';

const FormTitle = styled('div')(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '20px',
}));

const CancelButton = styled(Button)(() => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function TradeOrderDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tradeOrderData, setTradeOrderData] = useState([]);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  useEffect(() => {
    const fetchTradeDetails = async () => {
      const tradeId = query.get('id');

      setLoading(true);
      try {
        const response = await tradeService.getOrderById(tradeId);
        const data = response.data.data;
        if (data) {
          data.createdAt = getFormattedDate(
            data.createdAt,
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        setTradeOrderData(data);
      } catch (error) {
        console.error('Error fetching trade details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTradeDetails();
  }, []);

  const handleCancelButton = () => {
    navigate(ENDPOINTS.TRADE_ORDER);
  };

  // const handleEdit = () => {
  //   navigate(`${ENDPOINTS.TRADE_ORDER}/${id}`);
  // };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString() : 'NA';
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div className="cursor-pointer" onClick={handleCancelButton}>
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t('TRADE_ORDER_DETAILS')}</FormTitle>
      </div>

      <FormContainer>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Order No')}</div>
                <div className="list-value">
                  {tradeOrderData.orderNo || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Symbol')}</div>
                <div className="list-value">
                  {tradeOrderData?.symbol?.metalType || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Volume')}</div>
                <div className="list-value">
                  {tradeOrderData.weight || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Bid Price')}</div>
                <div className="list-value">
                  {tradeOrderData.bidPrice || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Live Price')}</div>
                <div className="list-value">
                  {tradeOrderData.currentPrice || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Total Profit ORder')}</div>
                <div className="list-value">
                  {tradeOrderData.takeProfitOrderValue || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Down payment')}</div>
                <div className="list-value">
                  {tradeOrderData.downPaymentAmount || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Created At')}</div>
                <div className="list-value">
                  {formatDate(tradeOrderData.createdAt)}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Closed At')}</div>
                <div className="list-value">
                  {formatDate(tradeOrderData.closedAt)}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Mode')}</div>
                <div className="list-value">{tradeOrderData.mode || 'NA'}</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Status')}</div>
                <div className="list-value">
                  {tradeOrderData.status || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Created At')}</div>
                <div className="list-value">
                  {tradeOrderData.createdAt || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Updated At')}</div>
                <div className="list-value">
                  {tradeOrderData.updatedAt || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="trade-container">
              <div className="list">
                <div className="list-heading">{t('Take Profit Unit Val')}</div>
                <div
                  className="list-value"
                  style={{
                    color:
                      Number(tradeOrderData.profitAmount) >= 0
                        ? Colors.SUCCESS
                        : Colors.ERROR,
                  }}
                >
                  {tradeOrderData.takeProfitUnitValue || 'NA'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-buttons">
          <CancelButton onClick={handleCancelButton}>{t('BACK')}</CancelButton>
        </div>
      </FormContainer>
    </div>
  );
}
