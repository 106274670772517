import * as Yup from 'yup';

export const bankDetailsSchema = Yup.object().shape({
  iBanNumber: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Field cannot be empty')
    .label('IBAN Number'),

  receiverName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Field cannot be empty')
    .label('Receiver Name'),

  bankName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Field cannot be empty')
    .label('Bank Name'),

  instructions: Yup.string()
    .min(2, 'Too Short!')
    .max(1000, 'Too Long!')
    .required('Field cannot be empty')
    .label('Instructions'),

  notes: Yup.string()
    .min(2, 'Too Short!')
    .max(1000, 'Too Long!')
    .required('Field cannot be empty')
    .label('Notes'),

  contactInfo: Yup.string()
    .min(2, 'Too Short!')
    .max(1000, 'Too Long!')
    .required('Field cannot be empty')
    .label('Contact Info'),
});
