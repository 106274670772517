import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  APP_LANGUAGE,
  ALL,
  routeMapping,
} from '../common/constants/AppConstants';
import moment from 'moment';
import { orderStatusConstant } from '../pages/order-details-page/orderConstant';
import ENDPOINTS from '../constants/endpoints';

export const errorToaster = (message, language = null) => {
  toast.error(message, {
    position: 'bottom-center',
    hideProgressBar: true,
    style: {
      backgroundColor: '#FDEAE0',
      color: '#CF3E18',
      textAlign: APP_LANGUAGE.ARABIC === language ? 'right' : 'left',
      whiteSpace: 'nowrap',
      width: 'min-content',
    },
  });
};

export const successToaster = (message, language = null) => {
  toast.success(message, {
    position: 'bottom-center',
    hideProgressBar: true,
    style: {
      backgroundColor: '#E4F1E3',
      color: '#17840B',
      textAlign: APP_LANGUAGE.ARABIC === language ? 'right' : 'left',
      whiteSpace: 'nowrap',
      width: 'min-content',
    },
  });
};

export const getFormattedDate = (date, format = 'DD/MM/YYYY') => {
  if (!date || !moment(date).isValid()) {
    return '';
  }
  return moment(date).format(format);
};

export const downloadExcelFile = (data, fileName = 'data-list') => {
  try {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}_template.xlsx`);
    document.body.appendChild(link);
    link.click();
    // Clean up resources
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }, 0);
  } catch (error) {
    console.error('Error downloading Excel file:', error);
  }
};
export const getModulePerm = (moduleCode) => {
  let usersDetails = localStorage.getItem('userDetails');
  usersDetails = JSON.parse(usersDetails ?? '{}');
  if (usersDetails.systemDefinedRole) {
    return { writeMode: true, readMode: true };
  }
  const permissions = usersDetails?.permissions ?? [];
  const data = permissions.find(
    (permission) => permission?.moduleCode === moduleCode
  );
  return data;
};

export const getFormattedName = (...name) =>
  name.reduce(
    (name, partialName) => name + (partialName ? ` ${partialName}` : ''),
    ''
  );

export const getFormattedAddress = ({ address, locality, city, zipCode }) =>
  [address, locality, city, zipCode].reduce(
    (address, partialAddress) =>
      address + (partialAddress ? ` ${partialAddress}` : ''),
    ''
  );

export function extractValuesIfNotTypeIsAll(object, key) {
  return object[key] === ALL ? undefined : object[key];
}

export function extractStartAndEndDate([startFilterDate, endFilterDate]) {
  let startDate, endDate;
  if (moment(endDate).isValid()) {
    startDate = getFormattedDate(startFilterDate, 'YYYY-MM-DD');
    endDate = getFormattedDate(
      moment(endFilterDate).add(1, 'days'),
      'YYYY-MM-DD'
    );
  }
  return { startDate, endDate };
}

function stringToSeed(str) {
  const values = [];
  for (let i = 0, len = str.length; i < len; i++) {
    values.push(str.charCodeAt(i));
  }
  // concatenatte and coerce to integer
  return values.join('') + 0;
}

export function randomColor(string) {
  const seed = stringToSeed(string);
  let hex = Math.floor(seed * 0xffffff);
  let color = '#' + hex.toString(16);
  return color;
}

export function getShortName(firstName, lastName) {
  let name = '';
  name += firstName.length ? firstName.charAt(0).toUpperCase() : '';
  name += lastName.length ? lastName.charAt(0).toUpperCase() : '';
  return name;
}

export function convertMegaBytesToBytes(sizeInMb) {
  return sizeInMb * 1024 * 1024;
}

export const convertToUpperCaseAndFormatDate = (string, isDate) => {
  return isDate
    ? getFormattedDate(string)
    : string.toUpperCase().replace(/_/g, ' ');
};

export const getStatusClassName = (status) => {
  switch (status) {
    case orderStatusConstant.REJECTED:
      return 'label-red';
    case orderStatusConstant.DELIVERED:
      return 'label-green';
    default:
      return 'label-yellow';
  }
};

export const getPhone = (data) => {
  if (!data) return;
  if (data.mobileCode)
    return `(+${data.mobileCode}) ${data.phone || data.phoneNo}`;
  return data.phone || data.phoneNo;
};

export const returnName = (data, includeComma = false) => {
  if (!data) return 'NA';
  const { firstName, middleName, lastName } = data;

  let fullName = `${firstName || ''} ${middleName || ''} ${
    lastName || ''
  }`.trim();

  if (includeComma && fullName) return `${fullName},`;

  return fullName || 'NA';
};

const getuserDetails = () => {
  return JSON.parse(localStorage.getItem('userDetails')) || {};
};
export const hasDashboardAccess = () => {
  let userDetails = getuserDetails();

  if (userDetails.systemDefinedRole) {
    return true;
  }
  const status = userDetails.permissions.find(
    (item) => item.moduleCode === 'DASHBOARD'
  );
  return status?.readMode || false;
};

export const getRespectiveUrl = () => {
  let userDetails = getuserDetails();

  if (userDetails.systemDefinedRole) {
    return ENDPOINTS.DASHBOARD;
  }
  const filteredItems = getListWithPermissions(userDetails.permissions);
  const getRoutes = routeMapping.filter(
    (item) => filteredItems.some(filteredItem => filteredItem.moduleCode === item.moduleCode)
  );
  return getRoutes.length > 0 ? getRoutes[0]['route'] : null; 
};
const getListWithPermissions = (list) => {
  return list.filter((item) => item.readMode);
};

export const changeLabelTranslation = (list, translator) => {
  return list.map((obj) => ({ ...obj, label: translator(obj?.label) }));
};

export function numberWithCommas(number) {
  var parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (!!parts[1]) parts[1] = parts[1].substring(0, 2);
  return parts.join('.');
}

export const normalizeSpaces = (str) => {
  return str.trim().replace(/\s+/g, ' ');
};

/**
 * Formats a date string to "DD/MM/YYYY HH:MM:SS"
 * @param {string} dateString - The date string to format
 * @returns {string} - The formatted date string
 */
export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-GB'); // "DD/MM/YYYY"
  const formattedTime = date.toLocaleTimeString('en-GB'); // "HH:MM:SS"
  return `${formattedDate} ${formattedTime}`;
};