import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import moment from 'moment';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar-debounce';
import { getAnnouncementList } from '../../api/announcement';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../components/date-range-picker';
import { Colors } from '../../common/constants/Color';
import { getModulePerm, getFormattedDate } from '../../utils/helperUtility';
import { MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import { RevealTextToRight } from '../../components/revealText/revealText';

const CLICK_EVENT_KEYS = {
  NEW_ANNOUNCEMENT: 'NEW_ANNOUNCEMENT',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function AnnouncemenetPage() {
  const { t, i18n } = useTranslation();
  const [announcementData, setAnnouncementData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const pageCount = Math.ceil(totalList / rowsPerPage);

  const handlePageChange = (event, value) => {
    console.log(`Page changed to: ${value}`);
    setCurrentPage(value);
    getTableData(value, rowsPerPage);
  };

  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
  });

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  const getListQueryPayload = () => {
    let dateFilterData = {};
    if (searchTerm) {
      dateFilterData['keyword'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['from'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['to'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    return dateFilterData;
  };

  useEffect(() => {
    getTableData(currentPage, rowsPerPage);
  }, [searchTerm, filterData]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    console.log(`Rows per page changed to: ${newRowsPerPage}`);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    console.log(
      `Fetching announcements: page=${page}, pageSize=${pageSize}, search=${searchTerm}`
    );
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getAnnouncementList({
        ...queryPayload,
        pageLimit: pageSize,
        pageSkip: page - 1,
      });
      setAnnouncementData(data.data);
      setLimit(data.limit);
      setRowsPerPage(parseInt(data.limit) || pageSize); // Keep rowsPerPage in sync
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching announcement data:', error);
      setLoading(false);
    }
  };

  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;
  const getCountry = (item) => {
    const { nameEng, nameAr } = item;
    if (!nameEng || !nameAr) return t('ALL');
    return isArabic ? nameAr : nameEng;
  };

  const currentModulePerm = getModulePerm('ANNOUNCEMENT_MANAGEMENT');
  const currentItems = announcementData.map((item) => ({
    id: item.id,
    title: item.title || 'NA',
    description: () => <RevealTextToRight text={item.description} /> || 'N/A',
    createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY, HH:mm'),
    country: getCountry(item),
  }));

  const roleColumns = [
    {
      key: 'title',
      title: t('TH_TITLE'),
      content: (rowData) => <strong>{rowData.name}</strong>,
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'description',
      title: t('DESCRIPTION'),
      requiredStyle: { width: '40%' },
      isComponent: true,
    },
    {
      key: 'createdAt',
      title: t('TH_CREATION_DATE'),
      sortButton: true,
      requiredStyle: { width: '20%%' },
    },
    {
      key: 'country',
      title: t('Country'),
      sortButton: true,
      requiredStyle: { width: '20%' },
    },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_ANNOUNCEMENT:
        navigate(ENDPOINTS.ANNOUNCEMENT_FORM);
        break;
    }
  };

  const handleFilterSelection = (key, event) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: event,
      };

      return data;
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('ANNOUNCEMENT_MANAGEMENT')}
        </Typography>

        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.NEW_ANNOUNCEMENT)}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_ANNOUNCEMENT')}
          </Button>
        )}
      </Stack>

      <Card>
        <StyledFilterBox>
          <div className="w-50 d-flex justify-content-start">
            <div className="ms-2">
              <DateRangePicker
                placeholder={t('DATE_PICKER_CREATION_DATE_TITLE')}
                dateRange={filterData.createdAt}
                popperPlacement="bottom-end"
                setDateRange={(event) =>
                  handleFilterSelection('createdAt', event)
                }
              />
            </div>
          </div>
          <SearchBar
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={t('Search')}
            maxlength={MAX_INPUT_LENGTH}
            onClear={handleClearSearch}
          />
        </StyledFilterBox>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={roleColumns}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          showPagination={true}
          pageCount={pageCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalList={totalList}
          itemsPerPage={rowsPerPage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          emptyTableText={t('NO_ANNOUNCEMENT_FOUND')}
        />
      </Card>
    </Container>
  );
}
