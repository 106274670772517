import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getLeases = (pageLimit = 10, pageSkip = 0) => {
  // For summary data, request all leases with pageLimit=all
  const endpoint = `${API_ENDPOINTS.LEASE}?pageLimit=${pageLimit}&pageSkip=${pageSkip}`;

  const payload = {
    endpoint,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
    paymentUrl: true,
  };

  console.log('Calling lease API with payload:', payload);
  return apiRequest(payload);
};

// New function to get summary data (all leases for metrics calculation)
export const getLeaseSummary = () => {
  const endpoint = `${API_ENDPOINTS.LEASE}?pageLimit=all&pageSkip=0`;

  const payload = {
    endpoint,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
    paymentUrl: true,
  };

  console.log('Calling lease summary API with payload:', payload);
  return apiRequest(payload);
};
