import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import SearchBar from '../../components/search-bar-debounce';
import { useTranslation } from 'react-i18next';
import { getFormattedDate, downloadExcelFile } from '../../utils/helperUtility';
import DateRangePicker from '../../components/date-range-picker';
import SelectInput from '../../components/select-input';
import moment from 'moment';
import { Colors } from '../../common/constants/Color';
import {
  getTransactionList,
  downloadTransactionFile,
} from '../../api/transactions';
import ENDPOINTS from '../../constants/endpoints';
import { TRANSACTION_SOURCE_MODE, sourceMapper } from './constant';
import { getThirdPartyClientListing } from '../../api/thirdPartyClient';
import useTableSearch from '../../hooks/useTableSearch';

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function TransactionManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
    status: 'All',
    metal: 'All',
    source: 'All',
    clientId: undefined,
  });
  const [clientList, setClientList] = useState([
    { value: undefined, label: 'All' },
  ]);

  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('success'), value: 'SUCCESS' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('cancelled'), value: 'CANCELLED' },
    { label: t('Failed'), value: 'FAILED' },
  ];

  const metalList = [
    { label: t('All'), value: 'All' },
    { label: t('Gold'), value: 'GOLD' },
    { label: t('Silver'), value: 'SILVER' },
  ];

  const sourceList = [
    { label: t('All'), value: 'All' },
    { label: t('GOLD_BUY'), value: 'gold_buy' },
    { label: t('SILVER_BUY'), value: 'silver_buy' },
    { label: t('SILVER_SELL'), value: 'silver_sell' },
    { label: t('GOLD_SELL'), value: 'gold_sell' },
    { label: t('WALLET_RECHARGE'), value: 'wallet_recharge' },
    { label: t('WITHDRAW'), value: 'withdraw' },
  ];

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };

  const navigate = useNavigate();

  const [transactionList, setTransactionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalList, settotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const itemsPerPage = parseInt(limit);

  const pageCount = Math.ceil(totalList / itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getData(value, rowsPerPage);
  };

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    try {
      const { data } = await getThirdPartyClientListing({ id: undefined });
      createClientFilterOptionList(data?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  const createClientFilterOptionList = (clientList) => {
    const clientFilterOptionList = clientList.map((client) => {
      return {
        value: client.id,
        label: client.name,
      };
    });
    setClientList((prev) => [...prev, ...clientFilterOptionList]);
  };

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={Colors.GRAY}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => goToViewDetails(rowItemData)}
          >
            {t('VIEW')}
          </Typography>
        </Box>
      </>
    );
  };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    if (newSearchTerm.length === 1) {
      if (newSearchTerm.charAt(newSearchTerm.length - 1) === ' ') return;
    } else if (newSearchTerm.length > 1) {
      if (
        newSearchTerm.charAt(newSearchTerm.length - 1) === ' ' &&
        newSearchTerm.charAt(newSearchTerm.length - 2) === ' '
      )
        return;
    }
    setCurrentPage(1);
    setSearchTerm(newSearchTerm);
  };
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getData(1, newRowsPerPage);
  };

  const goToViewDetails = (row) => {
    navigate(`${ENDPOINTS.WALLET_TRANSACTION_DETAILS}?transactionId=${row.id}`);
  };
  const ALL = 'All';
  const getListQueryPayload = () => {
    let dateFilterData = {};
    const { status, source, metal } = filterData;
    if (status && status !== ALL) {
      dateFilterData['status'] = filterData.status;
    }

    if (source && source !== ALL) {
      dateFilterData['source'] = filterData.source;
    }

    if (metal && metal !== ALL) {
      dateFilterData['metal'] = filterData.metal;
    }

    if (searchTerm) {
      dateFilterData['keyword'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['from'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['to'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    if (filterData?.clientId && filterData?.clientId !== 'All') {
      dateFilterData.fk_clients_id = filterData?.clientId;
    }
    return dateFilterData;
  };

  const handleExport = () => {
    const queryPayload = getListQueryPayload();
    queryPayload['download'] = true;
    queryPayload['fileType'] = 'xlsx';
    downloadTransactionFile(queryPayload, true)
      .then((data) => {
        downloadExcelFile(data, t('TRANSACTION_LIST'));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const TransactionColumns = [
    { key: 'transactionNo', title: t('Transaction ID') },
    { key: 'createdAt', title: t('Transaction Date') },
    { key: 'buyer_detail', title: t('Buyer details') },
    { key: 'emailId', title: t('CUST_EMAIL') },
    { key: 'mode', title: t('Mode') },
    {
      key: 'renderAmount',
      title: t('transaction amount'),
      sortButton: false,
      isComponent: true,
    },
    { key: 'source', title: t('Source') },
    { key: 'status', title: t('STATUS') },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  useEffect(() => {
    const getTransactions = async () => {
      getData();
    };
    getTransactions();
  }, [filterData, searchTerm]);

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const getData = async (page = 1, pageSize = rowsPerPage) => {
    try {
      setLoading(true);
      const query = {
        pageLimit: pageSize,
        pageSkip: page - 1,
        ...getListQueryPayload(),
      };
      const response = await getTransactionList(query);
      setTransactionList(response?.data?.data || []);
      settotalList(response?.data?.totalCount || 0);
      setLimit(response?.data?.limit);
      setCurrentPage(page);
      setLoading(false);
    } catch (err) {
      setTransactionList([]);
      settotalList(0);
      setLoading(false);
      console.log(err);
    }
  };
  const filteredData = useTableSearch(transactionList, searchTerm);
  const transformedData =
    filteredData?.map((item) => {
      return {
        ...item,
        id: item.id,
        mode: TRANSACTION_SOURCE_MODE[item.source]?.MODE || 'NA',
        renderAmount: () => {
          return (
            <div className="d-flex gap-1">
              <div>{item.amount}</div>
              <div>{`${item.currency || ''}`}</div>
            </div>
          );
        },
        source: TRANSACTION_SOURCE_MODE[item.source]?.SOURCE || 'NA',
        buyer_detail:
          item.firstName || item.middleName || item.lastName
            ? `${item.firstName || ''} ${item.middleName || ''} ${
                item.lastName || ''
              }`.trim()
            : 'NA',
        emailId: item.email || 'NA',
        createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss'),
        renderButton: () => <ButtonsForTable rowItemData={item} />,
      };
    }) || [];

  const handleFilterSelection = (key, event) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: event,
      };

      return data;
    });
  };
  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4" gutterBottom>
            {t('TRANSACTION_MANAGEMENT')}
          </Typography>

          {/* <Button
            onClick={handleExport}
            variant="contained"
            startIcon={
              <Iconify width={'18px'} icon="material-symbols:download-sharp" />
            }
          >
            {t('EXPORT')}
          </Button> */}
        </Stack>

        <Card>
          <Scrollbar>
            <Card>
              <StyledFilterBox>
                <SelectInput
                  placeholder={t('STATUS')}
                  labelInline={true}
                  containerSx={{ width: '120px' }}
                  label={t('STATUS')}
                  value={filterData.status}
                  options={statusList}
                  handleSelectChange={(event) => {
                    handleFilterSelection('status', event);
                  }}
                />
                <SelectInput
                  placeholder={t('METAL')}
                  labelInline={true}
                  containerSx={{ width: '120px' }}
                  label={'METAL'}
                  value={filterData.metal}
                  options={metalList}
                  handleSelectChange={(event) => {
                    handleFilterSelection('metal', event);
                  }}
                />
                <SelectInput
                  placeholder={t('Source')}
                  labelInline={true}
                  containerSx={{ width: '120px' }}
                  label={'SOURCE'}
                  value={filterData.source}
                  options={sourceList}
                  handleSelectChange={(event) => {
                    handleFilterSelection('source', event);
                  }}
                />
                <SelectInput
                  labelInline={true}
                  containerSx={{ width: '120px' }}
                  options={clientList}
                  value={filterData?.clientId}
                  handleSelectChange={(value) => {
                    setFilterData((prev) => ({
                      ...prev,
                      clientId: value,
                    }));
                  }}
                />
                <DateRangePicker
                  placeholder={t('DATE_PICKER_LABEL')}
                  dateRange={filterData.createdAt}
                  setDateRange={(event) =>
                    handleFilterSelection('createdAt', event)
                  }
                />
                <SearchBar
                  onChange={handleSearchChange}
                  value={searchTerm}
                  onClear={handleClearSearch}
                  placeholder={t('SEARCH_TRANSACTION_PLACEHOLDER')}
                  maxlength={50}
                />
              </StyledFilterBox>
              <CommonTable
                resourceName={resourceName}
                data={transformedData}
                columns={TransactionColumns}
                isCheckBox={false}
                loading={loading}
                showPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalList={totalList}
                itemsPerPage={limit}
                rowsPerPage={limit}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                emptyTableText={t('NO_TRANSACTION_FOUND')}
              />
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
