import { Hidden } from '@mui/material';
import { APP_LANGUAGE, Colors } from '../../../common/constants/AppConstants';
import WarningSymbol from './WarningSymbol.svg';

export const inputLabelStyle = (language) => ({
  fontWeight: 500,
  fontFamily: 'Montserrat',
  color: '#424242',
  fontSize: '14px',
  lineHeight: '16px',
  marginBottom: '4px',
  position: 'relative',
  textAlign: language === APP_LANGUAGE.ARABIC ? 'right' : 'left',
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '.asterisk-content': {
    position: 'relative',
  },
  '.asterisk-content::before': {
    color: '#C24622',
    top: '50%',
    right: '100%',
    paddingRight: '1px',
    transform: 'translateY(-23%)',
    content: '"*"',
    position: 'absolute',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
    display: APP_LANGUAGE.ENGLISH === language ? 'none' : 'inline',
  },
  '.asterisk-content::after': {
    color: '#C24622',
    top: '50%',
    left: '100%',
    paddingLeft: '1px',
    transform: 'translateY(-23%)',
    content: '"*"',
    position: 'absolute',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
    display: APP_LANGUAGE.ARABIC === language ? 'none' : 'inline',
  },
});

export const selectLabelStyle = (language) => ({
  fontWeight: 500,
  fontFamily: 'Montserrat',
  color: '#424242',
  fontSize: '14px',
  lineHeight: '16px',
  marginBottom: '4px',
  position: 'relative',
  textAlign: language === APP_LANGUAGE.ARABIC ? 'right' : 'left',
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '.asterisk-content': {
    position: 'relative',
  },
  '.asterisk-content::before': {
    color: '#C24622',
    top: '50%',
    right: '100%',
    paddingRight: '1px',
    transform: 'translateY(-23%)',
    content: '"*"',
    position: 'absolute',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
    display: APP_LANGUAGE.ENGLISH === language ? 'none' : 'inline',
  },
  '.asterisk-content::after': {
    color: '#C24622',
    top: '50%',
    left: '100%',
    paddingLeft: '1px',
    transform: 'translateY(-23%)',
    content: '"*"',
    position: 'absolute',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
    display: APP_LANGUAGE.ARABIC === language ? 'none' : 'inline',
  },
});

export const selectInputField = (isError, language) => ({
  height: '40px',
  backgroundColor: isError ? '#FEE9E8 !important' : 'none',
  borderRadius: '8px',
  borderWidth: '0.66px',
  gap: '4px',
  '& fieldset': {
    border: isError
      ? '0.66px solid #8E1F0B !important'
      : '0.66px solid #8A8A8A !important',
  },
  '& .Mui-focused': {
    backgroundColor: Colors.LIGHT_BACKGROUND,
  },
});

export const textInputField = (isError, language, manualErrorSvgMargin) => ({
  '& .MuiInputBase-root': {
    height: '40px',
    backgroundColor: isError ? '#FEE9E8 !important' : 'none',
    borderRadius: '8px',
    borderWidth: '0.66px',
    gap: '4px',
    '& fieldset': {
      border: isError
        ? '0.66px solid #8E1F0B !important'
        : '0.66px solid #8A8A8A !important',
    },
    '& .Mui-focused': {
      backgroundColor: Colors.LIGHT_BACKGROUND,
    },
    '& .MuiFormHelperText-root':
      language === APP_LANGUAGE.ARABIC
        ? {
            textAlign: 'right',
            marginTop: '4px',
          }
        : {
            marginTop: '4px',
          },

    '& .MuiFormHelperText-root::before':
      APP_LANGUAGE.ENGLISH === language && isError
        ? {
            position: 'absolute',
            content: `url("${WarningSymbol}")`,
            left: '2%',
          }
        : {},
    '& .MuiFormHelperText-root::after':
      APP_LANGUAGE.ARABIC === language && isError
        ? {
            position: 'absolute',
            content: `url("${WarningSymbol}")`,
            right: '2%',
          }
        : {},
  },
  '& .MuiFormHelperText-root':
    language === APP_LANGUAGE.ARABIC
      ? {
          textAlign: 'right',
          marginTop: '4px',
        }
      : {
          marginTop: '4px',
        },

  '& .MuiFormHelperText-root::before':
    APP_LANGUAGE.ENGLISH === language && isError
      ? {
          position: 'absolute',
          content: `url("${WarningSymbol}")`,
          left: manualErrorSvgMargin ? manualErrorSvgMargin : '2%',
        }
      : {},
  '& .MuiFormHelperText-root::after':
    APP_LANGUAGE.ARABIC === language && isError
      ? {
          position: 'absolute',
          content: `url("${WarningSymbol}")`,
          right: manualErrorSvgMargin ? manualErrorSvgMargin : '2%',
        }
      : {},
});

export const textInputFieldStyle = {
  padding: '6px 12px 6px 12px',
  lineHeight: '16px',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  fontWeight: '500',
  color: Colors.DARK_BACKGROUND,
};

export const textAreaInputFieldStyle = {
  padding: '6px 12px 6px 12px',
  lineHeight: '16px',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  fontWeight: '500',
  color: Colors.DARK_BACKGROUND,
  height: '100px !important',
};

export const linkTextStyle = {
  fontWeight: 500,
  fontSize: '12px',
  fontFamily: 'Montserrat',
  lineHeight: '16px',
  color: '#616161',
  cursor: 'pointer',
};

export const formButtonStyle = () => ({
  backgroundColor: Colors.DARK_BACKGROUND,
  height: '40px',
  textAlign: 'center',
  padding: '6px 12px',
  borderRadius: '8px',
  gap: '2px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 500,
  color: '#FFFFFF',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: Colors.DARK_BACKGROUND,
  },
  marginTop: '30px',
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.17)',
    color: '#FFFFFF',
  },
});

export const formHelperStyle = {
  paddingLeft: '2%',
  paddingRight: '2%',
};
