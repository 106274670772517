import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import SelectInput from '../../components/select-input/SelectInput';
import SearchBar from '../../components/search-bar-debounce';
import { getTicketsList } from '../../api/tickets';
import { Colors } from '../../common/constants/Color';
import ENDPOINTS from '../../constants/endpoints';
import { getFormattedName } from '../../utils/helperUtility';
import { TICKET_STATUS } from './constants';
import { RevealTextToRight } from '../../components/revealText/revealText';

const resourceName = {
  singular: 'ticket',
  plural: 'tickets',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function TicketManagement() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(limit);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const [ticketsData, setTicketsData] = useState([]);
  const [filterData, setFilterData] = useState({
    status: 'All',
  });

  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('OPEN'), value: 'OPEN' },
    { label: t('IN_PROGRESS'), value: 'IN_PROGRESS' },
    { label: t('CLOSED'), value: 'CLOSED' },
  ];

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value);
  };

  const getListQueryPayload = () => {
    let queryFilterData = {};
    if (filterData?.status && filterData.status !== 'All') {
      queryFilterData['status'] = filterData.status;
    }
    if (searchTerm) {
      queryFilterData['search_str'] = searchTerm;
    }
    return queryFilterData;
  };

  const goToTicketDetails = (item) => {
    navigate(`${ENDPOINTS.TICKET_MANAGEMENT_DETAILS}?id=${item.id}`);
  };

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
        <Typography
          fontWeight={'500'}
          fontSize={'12px'}
          fontFamily={'Inter'}
          color={`${Colors.GRAY}`}
          display={'flex'}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            goToTicketDetails(rowItemData);
          }}
        >
          {t('VIEW')}
        </Typography>
      </Box>
    );
  };

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getTicketsList({
        ...queryPayload,
        page_limit: itemsPerPage,
        page_skip: page - 1,
      });
      setTicketsData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tickets data:', error);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    const savedStatus = localStorage.getItem('ticketStatus');
    if (savedStatus) {
      setFilterData((prevData) => ({
        ...prevData,
        status: savedStatus,
      }));
    }
  }, []);

  const handleFilterSelection = (key, value) => {
    setFilterData((prevData) => {
      const newData = { ...prevData, [key]: value };
      if (key === 'status') {
        localStorage.setItem('ticketStatus', value);
      }
      return newData;
    });
  };

  const ticketsListData = ticketsData?.map((item) => {
    return {
      id: item.id || 'N/A',
      title: item?.title || 'N/A',
      description: () => <RevealTextToRight text={item?.description} /> || 'N/A',
      status:
        item?.status === TICKET_STATUS.IN_PROGRESS
          ? 'IN PROGRESS'
          : item.status,
      customerInfo: (
        <Box className="boxContent">
          {getFormattedName(item.userName) ?? ''} <br />
          {`${item.email}` ?? ''}
        </Box>
      ),
      renderButton: () => <ButtonsForTable rowItemData={item} />,
    };
  });

  const ticketColumns = [
    {
      key: 'title',
      title: t('TITLE'),
    },
    { key: 'description', title: t('DESCRIPTION'), isComponent: true, },
    { key: 'status', title: t('STATUS') },
    {
      key: 'customerInfo',
      title: t('Customer Info'),
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  useEffect(() => {
    getTableData();
  }, [filterData, searchTerm]);

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4" gutterBottom>
            {t('TICKET_MANAGEMENT')}
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <Card>
              <StyledFilterBox>
                <div className="w-50 d-flex justify-content-start">
                  <SelectInput
                    placeholder="Status"
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    label={'Status'}
                    value={filterData.status}
                    options={statusList}
                    handleSelectChange={(event) => {
                      handleFilterSelection('status', event);
                    }}
                  />
                </div>
                <SearchBar
                  onChange={handleSearchChange}
                  value={searchTerm}
                  onClear={handleClearSearch}
                  placeholder={t('Search')}
                  maxlength={50}
                />
              </StyledFilterBox>
              <CommonTable
                resourceName={resourceName}
                data={ticketsListData}
                columns={ticketColumns}
                isCheckBox={false}
                loading={loading}
                showPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                emptyTableText={t('NO_TICKETS_FOUND')}
                handlePageChange={handlePageChange}
                totalList={totalList}
                itemsPerPage={itemsPerPage}
              />
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
