import { useCallback, useEffect, useState } from 'react';
// @mui
import { Card, Stack, Container, Typography, Box } from '@mui/material';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// components

import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections

// Get Products Slice
import { useNavigate, createSearchParams } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
// ----------------------------------------------------------------------

import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import { Badge } from '@shopify/polaris';
import { getAdminList } from '../../redux/slices/adminList.slice';
import Pagination from '../../components/pagination';
import { debounce } from 'lodash';
import SearchBar from '../../components/search-bar';
import { useTranslation } from 'react-i18next';
import { REGEX_PATTERN } from '../../constants/regex';
import {
  getModulePerm,
  getFormattedDate,
  returnName,
} from '../../utils/helperUtility';
import {
  KYC_FILTER_OPTION,
  FILTER_OPTION,
  MODULE_CONST,
  KYC_STATUS_LABELS,
} from '../../common/constants/ModuleConstant';
import SelectInput from '../../components/select-input/SelectInput';
import { ROLE } from '../../constants/stringConstants';
import { getThirdPartyClientListing } from '../../api/thirdPartyClient';

// ----------------------------------------------------------------------

const getDebouncedSearchFunction = (fetchAdminList) => {
  return debounce((searchTerm) => {
    fetchAdminList(searchTerm);
  }, 500);
};

const MAX_LENGTH = 30;

export default function UserPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: userData,
    loading,
    totalList,
    limit,
  } = useSelector((state) => state.adminLists);

  const [searchTerm, setSearchTerm] = useState('');
  const [clientList, setClientList] = useState([
    { value: undefined, label: 'All' },
  ]);
  const [filter, setFilter] = useState({
    status: FILTER_OPTION.ALL,
    clientId: undefined,
  });
  const [kycFilter, setKycFilter] = useState({
    status: KYC_FILTER_OPTION.ALL,
    clientId: undefined,
  });

  // =================== POP OVER =======================

  // For Pagination
  //========================================================
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const pageCount = Math.ceil(totalList / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchAdminList(searchTerm, value);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
    fetchAdminList(searchTerm, 1, newRowsPerPage);
  };
  //========================================================

  const fetchAdminList = useCallback(
    (searchValue = '', page = 1, pageLimit = rowsPerPage) => {
      const pageSkip = page - 1;
      const reqData = {
        page_limit: pageLimit,
        page_skip: pageSkip,
        search_str: searchValue,
        role: ROLE.USER,
      };
      if (filter?.status === FILTER_OPTION.ACTIVE) {
        reqData.status = true;
      }
      if (filter?.status === FILTER_OPTION.INACTIVE) {
        reqData.status = false;
      }
      console.log(kycFilter?.status);
      if (kycFilter?.status === KYC_FILTER_OPTION.NOT_APPLIED) {
        reqData.kycStatus = 'NOT_APPLIED';
      }
      if (kycFilter?.status === KYC_FILTER_OPTION.APPROVED) {
        reqData.kycStatus = 'APPROVED';
      }
      if (kycFilter?.status === KYC_FILTER_OPTION.REJECTED) {
        reqData.kycStatus = 'REJECTED';
      }
      if (kycFilter?.status === KYC_FILTER_OPTION.PENDING) {
        reqData.kycStatus = 'PENDING';
      }

      // if (filter?.clientId && filter?.clientId !== 'All') {
      //   reqData.fk_clients_id = filter?.clientId;
      // }
      dispatch(
        getAdminList({
          reqData,
        })
      );
      setCurrentPage(page);
    },
    [rowsPerPage, filter, kycFilter, dispatch]
  );

  const debouncedFetchAdminList = useCallback(
    getDebouncedSearchFunction(fetchAdminList),
    [fetchAdminList]
  );

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    debouncedFetchAdminList(newSearchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    debouncedFetchAdminList('');
  };

  const getKYCStatusLabel = (status) => KYC_STATUS_LABELS[status] || 'Unknown';

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    try {
      const { data } = await getThirdPartyClientListing({ id: undefined });
      createClientFilterOptionList(data?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  const createClientFilterOptionList = (clientList) => {
    const clientFilterOptionList = clientList.map((client) => {
      return {
        value: client.id,
        label: client.name,
      };
    });
    setClientList((prev) => [...prev, ...clientFilterOptionList]);
  };

  useEffect(() => {
    fetchAdminList();
  }, [filter, kycFilter, dispatch]);

  useEffect(() => {
    return () => {
      debouncedFetchAdminList.cancel();
    };
  }, [debouncedFetchAdminList]);

  const currentModulePerm = getModulePerm(MODULE_CONST.USER_MANAGEMENT);

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={'#A5A4A2'}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() =>
              navigate(
                `${ENDPOINTS.DASHBOARD}/${
                  ENDPOINTS.VIEW_USER
                }?${createSearchParams({
                  id: rowItemData.id,
                }).toString()}`
              )
            }
          >
            {t('VIEW_BTN')}
          </Typography>
          {currentModulePerm.writeMode && (
            <Box display={'flex'} gap={'16px'}>
              <Box
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  currentModulePerm.writeMode &&
                  navigate(
                    `${ENDPOINTS.DASHBOARD}/${
                      ENDPOINTS.EDIT_USER
                    }?${createSearchParams({
                      id: rowItemData.id,
                    }).toString()}`
                  )
                }
              >
                <Iconify
                  icon="streamline:pencil"
                  width={'14px'}
                  color="primary"
                  sx={userPageStyles.penIconSx}
                />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const getRolesString = (roles) => {
    return !roles || roles.length === 0
      ? 'N/A'
      : roles.map((role) => role.name).join(', ');
  };

  const getPhoneWithCountryCode = (item) => {
    if (item.mobileCode) {
      return `(+${item.mobileCode}) ${item.phoneNo}`;
    }
    return item.phoneNo;
  };
  const currentItems = userData.map((item) => ({
    id: item.id,
    name:
      item.firstName || item.middleName || item.lastName
        ? `${item.firstName || ''} ${item.middleName || ''} ${
            item.lastName || ''
          }`.trim()
        : 'NA',
    email: item.email || 'NA',
    phone: getPhoneWithCountryCode(item),
    kycStatus: getKYCStatusLabel(item.kycStatus),
    createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss'),
    renderActive: () => {
      return item.isActive ? (
        <Badge tone="enabled" progress="complete">
          Active
        </Badge>
      ) : (
        <Badge tone="disabled">Inactive</Badge>
      );
    },
    role: returnName(item.accountManager),
    renderButton: () => <ButtonsForTable rowItemData={item} />,
  }));

  const roleColumns = [
    {
      key: 'name',
      title: t('TH_NAME'),
      content: (rowData) => <strong>{rowData.name}</strong>,
    },
    {
      key: 'email',
      title: t('EMAIL'),
    },
    {
      key: 'phone',
      title: t('PHONE'),
    },
    {
      key: 'kycStatus',
      title: t('KYC_STATUS'),
    },
    {
      key: 'createdAt',
      title: t('TH_CREATION_DATE'),
      align: 'center',
      sortButton: true,
    },
    {
      key: 'renderActive',
      title: t('TH_STATUS'),
      isComponent: true,
    },
    {
      key: 'role',
      title: t('ACCOUNT_MANAGER'),
      content: (rowData) => rowData.role,
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };
  useEffect(() => {
    const savedStatus = localStorage.getItem('kycStatus');
    const savedKYCStatus = localStorage.getItem('kycFilterStatus');
    if (savedStatus) {
      setFilter((prevData) => ({
        ...prevData,
        status: savedStatus,
      }));
    }
    if (savedKYCStatus) {
      setKycFilter((prevData) => ({
        ...prevData,
        status: savedKYCStatus,
      }));
    }
  }, []);

  const handleFilterSelection = (key, value) => {
    setFilter((prevData) => {
      const newData = { ...prevData, [key]: value };
      if (key === 'status') {
        localStorage.setItem('kycStatus', value);
      }
      return newData;
    });
  };
  const handleKYCFilterSelection = (key, value) => {
    setKycFilter((prevData) => {
      const newData = { ...prevData, [key]: value };
      if (key === 'status') {
        localStorage.setItem('kycFilterStatus', value);
      }
      return newData;
    });
  };
  return (
    <Box>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4">{t('USER_MANAGEMENT')}</Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <Card>
              <Box padding="8px" borderBottom="1px solid #E3E3E3">
                <Stack direction={'row'} justifyContent={'end'} gap={'10px'}>
                  <SelectInput
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    options={[
                      {
                        label: 'All',
                        value: FILTER_OPTION.ALL,
                      },
                      {
                        label: 'Active',
                        value: FILTER_OPTION.ACTIVE,
                      },
                      {
                        label: 'Inactive',
                        value: FILTER_OPTION.INACTIVE,
                      },
                    ]}
                    value={filter?.status}
                    handleSelectChange={(event) => {
                      handleFilterSelection('status', event);
                    }}
                  />
                  <SelectInput
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    options={[
                      { label: 'All', value: KYC_FILTER_OPTION.ALL },
                      { label: 'Approved', value: KYC_FILTER_OPTION.APPROVED },
                      { label: 'Pending', value: KYC_FILTER_OPTION.PENDING },
                      { label: 'Rejected', value: KYC_FILTER_OPTION.REJECTED },
                      {
                        label: 'Not Applied',
                        value: KYC_FILTER_OPTION['NOT APPLIED'],
                      },
                    ]}
                    value={kycFilter?.status}
                    handleSelectChange={(event) => {
                      handleKYCFilterSelection('status', event);
                    }}
                  />
                  <SelectInput
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    options={clientList}
                    value={filter?.clientId}
                    handleSelectChange={(event) => {
                      handleFilterSelection('clientId', event);
                    }}
                  />
                  <SearchBar
                    onChange={handleSearchChange}
                    value={searchTerm}
                    onClear={handleClearSearch}
                    placeholder={t('Search')}
                    maxlength={MAX_LENGTH}
                  />
                </Stack>
              </Box>
              <CommonTable
                resourceName={resourceName}
                data={currentItems}
                columns={roleColumns}
                isCheckBox={false}
                loading={loading}
                emptyTableText={t('NO_USER_FOUND')}
                onSortButtonClick={() => {}}
              />
              <Pagination
                page={currentPage}
                count={pageCount}
                onChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                totalItems={totalList}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              />
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </Box>
  );
}
