import React, { useState } from 'react';
import { Typography, Button, Collapse } from '@mui/material';

const RevealText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <Collapse in={expanded} collapsedSize={20}>
        <Typography variant="body2">{text}</Typography>
      </Collapse>
      <Button onClick={handleToggle} size="small">
        {expanded ? 'Show Less' : 'Show More'}
      </Button>
    </>
  );
};

const RevealTextToRight = ({ text }) => {
  const [revealed, setRevealed] = useState(false);
  const words = text.split(' ');

  const handleReveal = () => {
    setRevealed((prev) => !prev);
  };

  const shouldShowButton = (words.length > 1 && text.length >= 10) || text.length > 20;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography noWrap={!revealed} style={{ maxWidth: revealed ? 'none' : '20%' }}>
        {text}
      </Typography>
      {shouldShowButton && (
        <Button onClick={handleReveal} style={{ marginLeft: '8px', order: revealed ? 1 : 0 }}>
          {revealed ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </div>
  );
};

export { RevealText, RevealTextToRight };