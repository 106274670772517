import { useContext, useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from './../userPageStyle';
import Pagination from '../../components/pagination';
import { useTranslation } from 'react-i18next';
import './../AdminManagement/adminConstant';
import './../AdminManagement/adminManagement.css';
import { Colors } from './../../common/constants/Color';
import { downloadReport, getReportList } from '../../api/reports';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import {
  convertToUpperCaseAndFormatDate,
  getFormattedDate,
} from '../../utils/helperUtility';
import DeleteModal from '../../components/modal-components/delete-user-modal/DeleteModal';
import Iconify from '../../components/iconify/Iconify';
import { modalHeading } from './ReportManagement.style';
import { GenerateReport } from './GenerateReport';
import { NA } from '../../common/constants/AppConstants';

export const downloadFile = async (reportId) => {
  const response = await downloadReport(reportId);
  if (response?.data?.data) {
    const element = document.createElement('a');
    element.href = response?.data?.data;
    element.target = '_blank';
    document.body.appendChild(element);
    element.click();
  }
};

export function ReportListing() {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(true);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(limit);
  const [open, setOpen] = useState(false);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value);
  };

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleConfirmDeleteClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    getTableData();
  }, []);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const { data } = await getReportList({
        pageLimit: pageSize,
        pageSkip: page - 1,
      });
      setOrderData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching report data:', error);
      setLoading(false);
    }
  };

  const currentItems = orderData.map((order) => {
    return {
      ...order,
      id: order.id,
      createdAt: getFormattedDate(order.createdAt, 'DD/MM/YYYY, HH:mm:ss'),
      filterValues:
        order.filterValues !== '{}'
          ? Object.entries(JSON.parse(order.filterValues)).map(
              (filterEntry) => (
                <Box>
                  <Box>
                    {`${convertToUpperCaseAndFormatDate(
                      filterEntry[0]
                    )} = ${convertToUpperCaseAndFormatDate(
                      filterEntry[1],
                      ['start_date', 'end_date'].includes(filterEntry[0])
                    )}`}
                  </Box>
                </Box>
              )
            )
          : NA,
      downloadButton:
        order.status === 'COMPLETED' ? (
          <Stack justifyContent={'flex-end'} direction={'row'}>
            <Button
              variant="contained"
              onClick={() => downloadFile(order.id)}
              sx={{ width: '140px' }}
            >
              {t('Download')}
            </Button>
          </Stack>
        ) : (
          order.status === 'FAILED' && (
            <Box textAlign={'right'}>
              <Typography variant="body2">
                {order?.errorRemarks ?? NA}
              </Typography>
            </Box>
          )
        ),
    };
  });

  const roleColumns = [
    {
      key: 'label',
      title: t('Name'),
    },
    {
      key: 'status',
      title: t('TH_STATUS'),
    },
    {
      key: 'filterValues',
      title: t('APPLIED_FILTERS'),
    },
    {
      key: 'createdAt',
      title: t('Download Time'),
    },
    {
      key: 'downloadButton',
      title: t(''),
    },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4" gutterBottom>
            {t('Report Management')}
          </Typography>
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => setOpen(true)}
            startIcon={
              <Iconify width={'18px'} icon="icon-park-solid:add-one" />
            }
          >
            {t('Generate Report')}
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <Box
              className="filter-wrapper"
              sx={{ backgroundColor: Colors.PAPER_BACKGOUND }}
            >
              <Stack direction={'row'} flexGrow={1} columnGap={'18px'}></Stack>
            </Box>
            <CommonTable
              resourceName={resourceName}
              data={currentItems}
              columns={roleColumns}
              isCheckBox={false}
              loading={loading}
              showPagination={true}
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalList={totalList}
              itemsPerPage={itemsPerPage}
              rowsPerPage={itemsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onSortButtonClick={() => {}}
              handleStatusChange={handleStatusChange}
              emptyTableText={t('NO_REPORT_FOUND')}
            />
          </Scrollbar>
        </Card>
      </Container>
      <DeleteModal
        Heading={
          <Typography sx={modalHeading}>{t('Generate Report')}</Typography>
        }
        isopen={open}
        handleClose={handleModalClose}
        toCloseOnOutsideModalClick={true}
        handleConfirmDeleteClick={handleConfirmDeleteClick}
        isModalButtonsVisible={false}
      >
        <GenerateReport
          t={t}
          language={language}
          setOpen={setOpen}
          regenerateReport={getTableData}
        />
      </DeleteModal>
    </>
  );
}
