import { useEffect, useState, useContext } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../components/index-table/CommonTable';
import { userPageStyles } from './userPageStyle';
import { Badge } from '@shopify/polaris';
import Pagination from '../components/pagination';
import SearchBar from '../components/search-bar';
import { getadminList, editAdmin } from '../api/adminManagement';
import ENDPOINTS from '../constants/endpoints';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../components/modal-components/delete-user-modal/DeleteModal';
import { LanguageContext } from '../globalContext/LanguageSwitcher';
import SelectInput from '../components/select-input';
import { styled } from '@mui/material/styles';
import './AdminManagement/adminConstant';
import './AdminManagement/adminManagement.css';
import { statusvalue } from './AdminManagement/adminConstant';
import { role } from '../constants/role-constant';
import { REGEX_PATTERN } from '../constants/regex';

import {
  successToaster,
  errorToaster,
  getModulePerm,
  getFormattedDate,
} from '../utils/helperUtility';
import { MAX_INPUT_LENGTH } from '../common/constants/AppConstants';

const CLICK_EVENT_KEYS = {
  NEW_ADMIN: 'NEW_ADMIN',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  VIEW: 'VIEW',
};

export default function AdminManagementPage() {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [adminData, setAdminData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [filterByStatus, setFilterByStatus] = useState(true);

  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentRecord, setCurrentRecord] = useState({});
  const [status, setStatus] = useState({
    value: localStorage.getItem('adminUserStatus') || 'All',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(limit);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value, rowsPerPage);
  };

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, status]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const query = {
        role: role.ADMIN,
        pageLimit: pageSize,
        pageSkip: page - 1,
        search_str: searchTerm,
      };
      if (status.value !== statusvalue.ALL) {
        if (status.value == statusvalue.active) {
          query.status = true;
        } else {
          if (status.value == statusvalue.inActive) {
            query.status = false;
          }
        }
      }
      const { data } = await getadminList(query);
      setAdminData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  const currentModulePerm = getModulePerm('ADMIN_MANAGEMENT');

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={'#A5A4A2'}
            sx={{
              cursor: !rowItemData.systemDefinedUser
                ? 'pointer'
                : 'not-allowed',
            }}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.VIEW, rowItemData)}
          >
            {t('VIEW_BTN')}
          </Typography>
          {currentModulePerm.writeMode && (
            <Box display={'flex'} gap={'16px'}>
              <Box>
                <Iconify
                  icon="streamline:pencil"
                  width={'14px'}
                  color="primary"
                  title={t(
                    rowItemData.systemDefinedUser
                      ? 'NOT_ALLOWED_FOR_SYSTEM_ADMIN'
                      : 'EDIT'
                  )}
                  sx={{
                    ...userPageStyles.penIconSx,
                    cursor: !rowItemData.systemDefinedUser
                      ? 'pointer'
                      : 'not-allowed',
                  }}
                  onClick={() =>
                    currentModulePerm.writeMode &&
                    handleClickEvent(CLICK_EVENT_KEYS.EDIT, rowItemData)
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const getRolesString = (roles) => {
    return !roles || roles.length === 0
      ? 'N/A'
      : roles.map((role) => role.name).join(', ');
  };

  const currentItems = adminData.map((admin) => ({
    id: admin.id,
    // name: `${admin.firstName || ''} ${admin.lastName || ''}`,
    name:
      admin.firstName || admin.middleName || admin.lastName
        ? `${admin.firstName || ''} ${admin.middleName || ''} ${
            admin.lastName || ''
          }`.trim()
        : 'NA',
    email: admin.email || '',
    // createdAt: new Date(admin.createdAt).toLocaleString(),
    createdAt: getFormattedDate(admin.createdAt, 'DD/MM/YYYY HH:mm:ss'),
    renderActive: () => {
      return admin.isActive ? (
        <Badge tone="enabled" progress="complete">
          {t('ACTIVE_BADGE')}
        </Badge>
      ) : (
        <Badge tone="disabled">{t('INACTIVE_BADGE')}</Badge>
      );
    },
    role: getRolesString(admin.roles),
    renderButton: () => <ButtonsForTable rowItemData={admin} />,
  }));

  const roleColumns = [
    {
      key: 'name',
      title: t('TH_NAME'),
      content: (rowData) => <strong>{rowData.name}</strong>,
      sortButton: false,
    },
    {
      key: 'email',
      title: t('TH_AD_EMAIL'),
      sortButton: false,
      content: (rowData) => rowData.email,
    },

    { key: 'createdAt', title: t('TH_CREATION_DATE'), sortButton: true },
    {
      key: 'renderActive',
      title: t('TH_STATUS'),
      sortButton: true,
      isComponent: true,
    },
    {
      key: 'role',
      title: t('TH_ROLE_ADMIN'),
      sortButton: false,
      content: (rowData) => rowData.role,
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_ADMIN:
        navigate(ENDPOINTS.ADMIN_FORM);
        break;
      case CLICK_EVENT_KEYS.EDIT:
        if (data.systemDefinedUser) {
          return;
        }
        navigate(`${ENDPOINTS.ADMIN_FORM}?adminId=${data.id}`);
        break;
      case CLICK_EVENT_KEYS.VIEW:
        if (data.systemDefinedUser) {
          return;
        }
        navigate(`${ENDPOINTS.ADMIN_FORM}?adminId=${data.id}&view=${true}`);
        break;
      case CLICK_EVENT_KEYS.DELETE:
        if (data.systemDefinedUser) {
          return;
        }
        setIsOpen(true);
        const { firstName, middleName, lastName } = data;
        const modifieDate = {
          ...data,
          name: `${firstName || ''} ${middleName || ''} ${lastName || ''}`,
        };
        setCurrentRecord(modifieDate);
        break;
    }
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setCurrentRecord({});
  };
  const deleteAdminRecord = async (id) => {
    try {
      setLoading(true);
      const response = await editAdmin({ isActive: false }, id);
      setIsOpen(false);
      setCurrentRecord({});
      if (response.data.isSuccess) {
        successToaster(t('ADMIN_DELETE_SUCCESS'), language);
        getTableData();
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };
  const handleConfirmDeleteClick = () => {
    const id = currentRecord.id;
    deleteAdminRecord(id);
  };
  useEffect(() => {
    const savedStatus = localStorage.getItem('adminUserStatus');
    if (savedStatus) {
      setStatus({ value: savedStatus });
    }
  }, []);

  const handleFilterSelection = (key, event) => {
    const selectedStatus = event;
    setStatus({
      value: selectedStatus,
    });
    localStorage.setItem('adminUserStatus', selectedStatus);
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('ADMIN_MANAGEMENT')}
        </Typography>

        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.NEW_ADMIN)}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_NEW_ADMIN')}
          </Button>
        )}
      </Stack>

      <Card>
        <Scrollbar>
          <div className="filter-wrapper">
            <SelectInput
              placeholder={t('Status')}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={t('Status')}
              value={status.value}
              options={[
                { label: t('ALL'), value: 'All' },
                { label: t('ACTIVE'), value: 'active' },
                { label: t('INACTIVE'), value: 'inActive' },
              ]}
              handleSelectChange={(event) => {
                handleFilterSelection('status', event);
              }}
            />
            <SearchBar
              onChange={handleSearchChange}
              value={searchTerm}
              placeholder={t('Search')}
              maxlength={MAX_INPUT_LENGTH}
              onClear={handleClearSearch}
            />
          </div>
          <CommonTable
            resourceName={resourceName}
            data={currentItems}
            columns={roleColumns}
            isCheckBox={false}
            loading={loading}
            onSortButtonClick={() => {}}
            handleStatusChange={handleStatusChange}
            showPagination={true}
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalList={totalList}
            itemsPerPage={itemsPerPage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            emptyTableText={t('NO_ADMIN_FOUND')}
          />
        </Scrollbar>
      </Card>
      <DeleteModal
        isopen={isOpen}
        handleClose={handleModalClose}
        toCloseOnOutsideModalClick={true}
        handleConfirmDeleteClick={handleConfirmDeleteClick}
      >
        <Box marginTop="32px">
          <Typography sx={userPageStyles.subHeadSx}>
            {t('DELETE_MODAL_SUB_HEAD')}
          </Typography>
          <Box display="flex" gap="16px" marginTop="16px">
            <Box
              height={'56px'}
              width={'56px'}
              borderRadius={'50%'}
              bgcolor={'lightGray'}
            ></Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography sx={userPageStyles.roleTextSx}>
                {currentRecord?.name}
              </Typography>
              <Typography sx={userPageStyles.roleTextSx}>
                {currentRecord?.role}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DeleteModal>
    </Container>
  );
}
