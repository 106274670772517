import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { useSearchParams } from 'react-router-dom';
import { LeaseInformation } from './LeaseInformation';
import { LeaseSummary } from './LeaseSummary';
import { errorToaster } from '../../utils/helperUtility';
import { Header } from '../../components/add-user/Header';
import { getLeases, getLeaseSummary } from '../../api/lease';

const LeaseDetails = () => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();

  // State for lease summary data (all leases)
  const [summaryData, setSummaryData] = useState(null);
  // State for paginated lease data
  const [leaseData, setLeaseData] = useState(null);

  // Loading states
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isLeaseDataLoading, setIsLeaseDataLoading] = useState(true);

  // Pagination state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLeases, setTotalLeases] = useState(0);

  // We still get userId from URL for potential future use
  const userId = searchParams.get('id');

  // Function to fetch lease summary data
  const fetchLeaseSummary = async () => {
    setIsSummaryLoading(true);

    try {
      const response = await getLeaseSummary();

      if (response && response.status === 200 && response.data) {
        if (response.data.isSuccess) {
          const summaryData = response.data.data;
          setSummaryData(summaryData);

          // Set the total count of leases for pagination
          if (summaryData && summaryData.leases) {
            setTotalLeases(summaryData.leases.length || 0);
          } else if (response.data.totalCount) {
            setTotalLeases(response.data.totalCount);
          }
        } else {
          errorToaster(
            response.data.message || t('ERROR_FETCHING_LEASE_SUMMARY'),
            language
          );
        }
      }
    } catch (error) {
      errorToaster(t('ERROR_FETCHING_LEASE_SUMMARY'), language);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  // Function to fetch paginated lease data
  const fetchPaginatedLeaseData = async (currentPage, rowsPerPage) => {
    setIsLeaseDataLoading(true);

    try {
      // Calculate pageSkip based on current page and rows per page
      const pageSkip = (currentPage - 1) * rowsPerPage;

      // Call getLeases with pagination parameters
      const response = await getLeases(rowsPerPage, pageSkip);

      if (response && response.status === 200 && response.data) {
        if (response.data.isSuccess) {
          setLeaseData(response.data.data);

          // Only update total leases if we don't have summary data yet
          if (!summaryData && response.data.totalCount !== undefined) {
            setTotalLeases(response.data.totalCount);
          }
        } else {
          errorToaster(
            response.data.message || t('ERROR_FETCHING_LEASE_DATA'),
            language
          );
        }
      } else {
        errorToaster(t('ERROR_FETCHING_LEASE_DATA'), language);
      }
    } catch (error) {
      errorToaster(t('ERROR_FETCHING_LEASE_DATA'), language);
    } finally {
      setIsLeaseDataLoading(false);
    }
  };

  // Handle pagination change
  const handlePaginationChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetchPaginatedLeaseData(newPage, newRowsPerPage);
  };

  // Combined loading state - true if any data is still loading
  const isLoading = isSummaryLoading || isLeaseDataLoading;

  // Initial data fetch
  useEffect(() => {
    // First fetch summary data (for all leases)
    fetchLeaseSummary();

    // Then fetch the first page of lease data
    fetchPaginatedLeaseData(page, rowsPerPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, t]); // Dependency array with comment to suppress linting warning

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
        {isLoading ? (
          // Show loader until all data is loaded
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
            <CircularProgress />
          </Box>
        ) : !summaryData ? (
          // Show message if no summary data after loading is complete
          <Paper sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h6">{t('No lease data available')}</Typography>
          </Paper>
        ) : (
          // Show data when everything is loaded
          <Box>
            <LeaseSummary leaseData={summaryData} />
            <LeaseInformation
              leaseData={leaseData}
              isLoading={isLeaseDataLoading}
              onPaginationChange={handlePaginationChange}
              currentPage={page}
              rowsPerPage={rowsPerPage}
              totalLeases={totalLeases}
              // Force the component to use our totalLeases value
              forceTotal={true}
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default LeaseDetails;
