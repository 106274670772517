import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function ImagePreview(props) {
  const imageList = props.imageList || [];

  return (
    <Carousel
      autoPlay
      showThumbs={false}
      interval={4000}
      infiniteLoop={true}
      showIndicators={false}
    >
      {imageList &&
        imageList.map((item) => {
          return (
            <div key={item.url}>
              <img height="100" width="80" src={item.url} />
            </div>
          );
        })}
    </Carousel>
  );
}
