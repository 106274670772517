import React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  IconButton,
} from '@mui/material';
import Iconify from '../../components/iconify/Iconify';

const Pagination = ({
  page,
  count,
  onChange,
  rowsPerPage,
  onRowsPerPageChange,
  totalItems,
  rowsPerPageOptions = [10, 25, 50, 100],
}) => {
  // Calculate current range of items being displayed
  const fromItem = Math.min(page * rowsPerPage - rowsPerPage + 1, totalItems);
  const toItem = Math.min(page * rowsPerPage, totalItems);

  // Generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Show at most 5 pages between prev and next buttons

    // Always show first page
    pageNumbers.push(1);

    let startPage = Math.max(2, page - 1);
    let endPage = Math.min(count - 1, page + 1);

    // Adjust to show up to maxPagesToShow pages
    if (endPage - startPage + 1 < maxPagesToShow) {
      if (startPage === 2) {
        endPage = Math.min(count - 1, startPage + maxPagesToShow - 2);
      } else if (endPage === count - 1) {
        startPage = Math.max(2, endPage - maxPagesToShow + 2);
      }
    }

    // Add ellipsis after first page if needed
    if (startPage > 2) {
      pageNumbers.push('...');
    }

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis before last page if needed
    if (endPage < count - 1) {
      pageNumbers.push('...');
    }

    // Always show last page if it exists and is different from first page
    if (count > 1) {
      pageNumbers.push(count);
    }

    return pageNumbers;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderTop: '1px solid #EAEAEA',
      }}
    >
      {/* Left side - Rows per page */}
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" color="text.secondary">
            Rows per page
          </Typography>
          <FormControl size="small" variant="outlined" sx={{ minWidth: 70 }}>
            <Select
              value={rowsPerPage}
              onChange={onRowsPerPageChange}
              sx={{
                height: '32px',
                '& .MuiSelect-select': {
                  paddingTop: '4px',
                  paddingBottom: '4px',
                },
              }}
            >
              {rowsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>

      {/* Middle - Page navigation */}
      <Stack direction="row" spacing={0.5} alignItems="center">
        {/* Previous page button */}
        <IconButton
          size="small"
          onClick={(e) => onChange(e, page - 1)}
          disabled={page === 1}
          sx={{ color: page === 1 ? 'text.disabled' : 'text.primary' }}
        >
          <Iconify icon="eva:chevron-left-fill" width={20} />
        </IconButton>

        {/* Page numbers */}
        {getPageNumbers().map((pageNum, index) =>
          pageNum === '...' ? (
            <Typography
              key={`ellipsis-${index}`}
              variant="body2"
              color="text.secondary"
              sx={{ mx: 1 }}
            >
              ...
            </Typography>
          ) : (
            <IconButton
              key={`page-${pageNum}`}
              size="small"
              onClick={(e) => onChange(e, pageNum)}
              sx={{
                width: 32,
                height: 32,
                fontWeight: page === pageNum ? 'bold' : 'normal',
                color: page === pageNum ? 'white' : 'text.primary',
                bgcolor: page === pageNum ? 'primary.main' : 'transparent',
                '&:hover': {
                  bgcolor: page === pageNum ? 'primary.dark' : 'action.hover',
                },
              }}
            >
              {pageNum}
            </IconButton>
          )
        )}

        {/* Next page button */}
        <IconButton
          size="small"
          onClick={(e) => onChange(e, page + 1)}
          disabled={page === count}
          sx={{ color: page === count ? 'text.disabled' : 'text.primary' }}
        >
          <Iconify icon="eva:chevron-right-fill" width={20} />
        </IconButton>
      </Stack>

      {/* Right side - Showing X-Y of Z */}
      <Typography variant="body2" color="text.secondary">
        Showing {fromItem}-{toItem} from {totalItems} results
      </Typography>
    </Box>
  );
};

export default Pagination;
