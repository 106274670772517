import API_ENDPOINTS from '../constants/apiEndpoints';
import apiRequest, { METHODS } from './apiRequest';

export const getUserDetails = (id) =>
  apiRequest({
    endpoint: `${API_ENDPOINTS.ADMIN_USER_DETAILS}${id}`,
    method: METHODS.GET,
    privateURL: true,
    headers: {},
  });

  export const getUserGoals = (query) =>
    apiRequest({
      endpoint: `${API_ENDPOINTS.USER_GOALS}`,
      method: METHODS.GET,
      privateURL: true,
      headers: {},
      query: query,
      paymentUrl: true,
    });