import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  IconButton,
  Popover,
  MenuItem,
  Button,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import AlertDialog from '../Bank Transfers/BankTransferConfirmationModal';
import config from '../../config';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import SearchBar from '../../components/search-bar-debounce';
import CommonTable from '../../components/index-table/CommonTable';
import useOutsideClick from '../../hooks/useOutsideClick';
import SelectInput from '../../components/select-input/SelectInput';
import { userPageStyles } from '../userPageStyle';
import { getFormattedDate, getModulePerm } from '../../utils/helperUtility';
import {
  acceptRejectBankDetails,
  getBankTranferList,
  handleManualDeposit,
} from '../../api/bankTransfers';
import { TRANSFER_STATUS, bankTransferConstants } from './constants';
import API_ENDPOINTS from '../../constants/apiEndpoints';
import ENDPOINTS from '../../constants/endpoints';
import { NA } from '../../common/constants/AppConstants';
import { Colors } from '../../common/constants/Color';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';

const resourceName = {
  singular: 'transfer',
  plural: 'transfers',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

const StyledStack = styled('div')(({ theme }) => {
  return {
    zIndex: 100,
    position: 'absolute',
    right: '10px',
    backgroundColor: 'white',
    boxShadow: theme.customShadows.card,
    borderRadius: '5px',
  };
});

const ViewFilesBtn = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.BLACK}`,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '4px, 22px, 4px, 22px',
  height: '25px',
  border: `1px solid ${Colors.LIGHT_YELLOW}`,
  borderRadius: '8px',
  width: '104px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

export default function BankTransfers() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('Approved'), value: 'APPROVED' },
    { label: t('Rejected'), value: 'REJECTED' },
  ];
  const currentModulePerm = getModulePerm(MODULE_CONST.BANK_TRANSFERS);
  const [pressedPopupId, setPressedPopupId] = useState(undefined);
  const elementNotToBeConsidered = useRef(null);
  const { componentRef } = useOutsideClick({
    elementNotToBeConsidered: elementNotToBeConsidered,
    onOutsideClick: () => setPressedPopupId(null),
  });
  const [isShowModal, setIsShowModal] = useState(null);
  const [id, setId] = useState(null);
  const [ModalMessage, setModalMessage] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [isManualDeposit, setIsManualDeposit] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(limit);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const [bankTransferData, setbankTransferData] = useState([]);
  const [filterData, setFilterData] = useState({
    status: 'All',
  });

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value, rowsPerPage);
  };

  const getListQueryPayload = () => {
    let queryFilterData = {};
    if (filterData?.status && filterData.status !== 'All') {
      queryFilterData['status'] = filterData.status;
    }
    if (searchTerm) {
      queryFilterData['search_str'] = searchTerm;
    }
    return queryFilterData;
  };

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <div>
        <IconButton onClick={() => setPressedPopupId(rowItemData?.id)}>
          <MoreVert ref={elementNotToBeConsidered} />
        </IconButton>
        {rowItemData?.id === pressedPopupId && (
          <StyledStack ref={componentRef} spacing={0.75}>
            <MenuItem
              key={1}
              onClick={() =>
                handleMenuItemClick({
                  transferId: rowItemData?.id,
                  action: bankTransferConstants.ACCEPT,
                })
              }
            >
              {t('APPROVE')}
            </MenuItem>
            <MenuItem
              key={2}
              onClick={() =>
                handleMenuItemClick({
                  transferId: rowItemData?.id,
                  action: bankTransferConstants.REJECT,
                })
              }
            >
              {t('REJECT')}
            </MenuItem>
          </StyledStack>
        )}
      </div>
    );
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getBankTranferList({
        ...queryPayload,
        pageLimit: pageSize,
        pageSkip: page - 1,
      });
      setbankTransferData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tickets data:', error);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    const savedStatus = localStorage.getItem('transferStatus');
    if (savedStatus) {
      setFilterData((prevData) => ({
        ...prevData,
        status: savedStatus,
      }));
    }
  }, []);

  const handleFilterSelection = (key, value) => {
    setFilterData((prevData) => {
      const newData = { ...prevData, [key]: value };
      if (key === 'status') {
        localStorage.setItem('transferStatus', value);
      }
      return newData;
    });
  };

  const openInNewTab = (id) => {
    const { API } = config;
    const BASE_URL = API.REACT_APP_BASE_URL;
    const url = `${BASE_URL}/public${API_ENDPOINTS.FILES}/${id}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const handleCancelButton = () => {
    navigate(ENDPOINTS.BANK_TRANSFERS);
  };

  const bankTranfersListData = bankTransferData?.map((item) => {
    const fullName = [item?.firstName, item?.middleName, item?.lastName]
      .filter(Boolean)
      .join(' ');
    const completePhoneNumber = [item?.mobileCode, item?.phoneNo]
      .filter(Boolean)
      .join('-');
    const completeCustomerInfo = [fullName, item?.email, completePhoneNumber]
      .filter(Boolean)
      .map((info, index, array) => (
        <>
          {info}
          {index < array.length - 1 && <br />}
        </>
      ));
    return {
      id: item?.id || t(`${NA}`),
      date: getFormattedDate(item?.createdAt || '', 'DD/MM/YYYY HH:mm:ss'),
      customerInfo: completeCustomerInfo,
      bankReferenceNumber: item?.bankReferenceNo || t(`${NA}`),
      amount:
        item?.status === TRANSFER_STATUS.PENDING ||
        item?.status === TRANSFER_STATUS.REJECTED
          ? t(`${NA}`)
          : item?.amount || t(`${NA}`),
      remarks: item?.rejectRemarks || t(`${NA}`),
      status:
        t(`${item?.status[0]}${item?.status.substring(1).toLowerCase()}`) || NA,
      renderReceipt: () => {
        return item?.receiptDocumentId ? (
          <ViewFilesBtn
            onClick={() => {
              openInNewTab(item?.receiptDocumentId);
            }}
          >
            {t('VIEW_FILE')}
          </ViewFilesBtn>
        ) : (
          <Typography variant="body2" display="block">
            N/A
          </Typography>
        );
      },
      renderButton: () =>
        item?.status === TRANSFER_STATUS.PENDING &&
        currentModulePerm.writeMode && <ButtonsForTable rowItemData={item} />,
    };
  });

  const bankTranfersColumns = [
    {
      key: 'date',
      title: t('Date'),
      requiredStyle: { width: '20%' },
    },
    {
      key: 'customerInfo',
      title: t('Customer Info'),
      requiredStyle: { width: '25%' },
    },
    {
      key: 'bankReferenceNumber',
      title: t('BANK_REFERENCE_NUMBER'),
      requiredStyle: { width: '30%' },
    },
    {
      key: 'amount',
      title: t('AMOUNT'),
      requiredStyle: { width: '5%' },
    },
    {
      key: 'remarks',
      title: t('REMARK'),
      requiredStyle: { width: '15%' },
    },
    {
      key: 'status',
      title: t('STATUS'),
      requiredStyle: { width: '5%' },
    },
    {
      align: 'center',
      key: 'renderReceipt',
      title: t('RECEIPT'),
      isComponent: true,
      requiredStyle: { width: '5%' },
    },
    {
      key: 'renderButton',
      title: '',
      isComponent: true,
      requiredStyle: { width: '5%' },
    },
  ];

  useEffect(() => {
    getTableData();
  }, [filterData, searchTerm]);

  useEffect(() => {
    if (!isShowModal) getTableData();
  }, [isShowModal]);

  const handleClose = () => {
    setPressedPopupId(undefined);
  };

  const getCustomerName = () => {
    return `${bankTransferData.customerName || ''}`;
  };

  const handleMenuItemClick = ({ transferId, action }) => {
    handleClose();
    const msg =
      action === bankTransferConstants.ACCEPT
        ? `${t('ACCEPT_TRANSFER_MSG')} ${getCustomerName()}`
        : `${t('REJECT_TRANSFER_MSG')} ${getCustomerName()}`;
    setModalMessage(msg);
    setActionType(
      action === bankTransferConstants.ACCEPT
        ? bankTransferConstants.ACCEPT
        : bankTransferConstants.REJECT
    );
    setId(transferId);
    setIsShowModal(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsShowModal(false);
    setIsManualDeposit(false);
  };

  const handleConfirm = async ({ data, action }) => {
    if (action === bankTransferConstants.ACCEPT_STATUS) {
      await acceptRejectBankDetails({
        id: data?.id,
        amount: data?.amount,
        bankReferenceNumber: data?.bankReferenceNumber,
        action: action,
      });
    } else if (action === bankTransferConstants.REJECT_STATUS) {
      await acceptRejectBankDetails({
        id: data?.id,
        remark: data?.remark,
        action: action,
      });
    } else {
      handleManualDeposit(data);
    }
  };

  const addManualDeposit = () => {
    setIsManualDeposit(true);
    setActionType(bankTransferConstants.MANUAL_DEPOSIT);
    setModalMessage(t('MANUAL_DEPOSIT_MSG'));
    setIsShowModal(true);
  };

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4" gutterBottom>
            {t('BANK_TRANSFERS')}
          </Typography>
          {currentModulePerm.writeMode && (
            <Button
              variant="contained"
              sx={userPageStyles.buttonSx}
              onClick={() => addManualDeposit()}
              startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
            >
              {t('MANUAL_DEPOSIT')}
            </Button>
          )}
        </Stack>
        <Card>
          <Scrollbar>
            <Card>
              <StyledFilterBox>
                <div className="w-50 d-flex justify-content-start">
                  <SelectInput
                    placeholder={t('STATUS')}
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    label={t('STATUS')}
                    value={filterData.status}
                    options={statusList}
                    handleSelectChange={(event) => {
                      handleFilterSelection('status', event);
                    }}
                  />
                </div>
                <SearchBar
                  onChange={handleSearchChange}
                  value={searchTerm}
                  onClear={handleClearSearch}
                  placeholder={t('Search')}
                  maxlength={50}
                />
              </StyledFilterBox>
              <CommonTable
                resourceName={resourceName}
                data={bankTranfersListData}
                columns={bankTranfersColumns}
                isCheckBox={false}
                loading={loading}
                showPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalList={totalList}
                itemsPerPage={itemsPerPage}
                rowsPerPage={itemsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                emptyTableText={t('NO_TRANSFERS_FOUND')}
              />
              {isShowModal && (
                <AlertDialog
                  open={isShowModal}
                  handleClose={handleModalClose}
                  handleConfirm={handleConfirm}
                  actionType={actionType}
                  title={
                    isManualDeposit
                      ? t('ADD_MANUAL_DEPOSIT')
                      : t('BANK_TRANSFER_VERIFICATION')
                  }
                  // reloadTableData={getTableData}
                  description={t(ModalMessage)}
                  transferId={id}
                  ModalMessage
                />
              )}
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
