import { createSlice } from '@reduxjs/toolkit';
import { getKybList as getKybListApi } from '../../api/kyb';
import asyncActionCreator from '../../common/utils/asyncActionCreator';

export const kybListSliceName = 'kybList';

export const getKybList = asyncActionCreator(
  `${kybListSliceName}/list`,
  getKybListApi,
  {
    doNotNotify: true,
    doNotLoad: true,
  }
);

const initialState = {
  loading: true,
  error: false,
  data: [],
  totalList: 0,
  limit: 10,
};

const getKybListSlice = createSlice({
  name: kybListSliceName,
  initialState,
  reducers: {
    resetKybList: (state) => {
      state.data = []; // Reset to an empty array
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.totalList = initialState.totalList;
      state.limit = initialState.limit;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKybList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getKybList.fulfilled, (state, action) => {
        //console.log('Fulfilled action payload:', action.payload); // Debug log
        state.loading = false;
        state.error = false;
        // Safely access nested data properties
        state.data = action.payload?.data?.data || action.payload?.data || [];
        state.totalList =
          action.payload?.data?.totalCount || action.payload?.totalCount || 0;
        state.limit =
          action.payload?.data?.limit || action.payload?.limit || 10;
      })
      .addCase(getKybList.rejected, (state, action) => {
        console.log('Rejected action error:', action.error); // Debug log
        state.loading = false;
        state.error = action.error?.message || 'An unknown error occurred.';
        state.data = [];
      });
  },
});

export const { resetKybList } = getKybListSlice.actions;

export default getKybListSlice.reducer;
