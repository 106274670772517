import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../iconify/Iconify';
import { Colors } from '../../common/constants/Color';
import { getRewardsTransactions } from '../../api/getRewardsTransactions';
import { getFormattedDate } from '../../utils/helperUtility';

export const XpRewardsPopUp = ({ open, onClose, userId }) => {
  const { t } = useTranslation();
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRewardsData = async () => {
      if (!open || !userId) return;

      setLoading(true);
      setError(null);

      try {
        // Use the API function to get rewards transactions
        const response = await getRewardsTransactions(userId);

        if (response.data.isSuccess) {
          // Filter transactions for the specific user if needed
          // (This may not be necessary if the API already filters by userId)
          const userRewards = response.data.data.filter(
            (reward) => reward.fkUsersId === userId
          );
          setRewards(userRewards);
        } else {
          setError(response.data.message || 'Failed to fetch rewards data');
        }
      } catch (err) {
        setError(
          err.message || 'An error occurred while fetching rewards data'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchRewardsData();
  }, [open, userId]);

  const getRewardTypeLabel = (type) => {
    switch (type) {
      case 'XP_POINTS':
        return t('XP Points');
      default:
        return type;
    }
  };

  const getRewardCategoryLabel = (category) => {
    switch (category) {
      case 'USER_GOALS':
        return t('User Goals');
      case 'SPIN_AND_WIN':
        return t('Spin and Win');
      case 'REFERAL':
        return t('Referral');
      default:
        return category;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${Colors.LIGHT_GREY}`,
        }}
      >
        <Typography variant="h6" component="div">
          {t('XP Rewards History')}
        </Typography>
        <IconButton onClick={onClose}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box sx={{ p: 2, color: Colors.ERROR }}>
            <Typography>{error}</Typography>
          </Box>
        ) : rewards.length > 0 ? (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead sx={{ backgroundColor: Colors.LIGHT_BACKGROUND }}>
                <TableRow>
                  <TableCell>{t('Date')}</TableCell>
                  <TableCell>{t('Points')}</TableCell>
                  <TableCell>{t('Type')}</TableCell>
                  <TableCell>{t('Category')}</TableCell>
                  <TableCell>{t('Metal Type')}</TableCell>
                  <TableCell>{t('Mode')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rewards.map((reward) => (
                  <TableRow key={reward.id}>
                    <TableCell>
                      {getFormattedDate(reward.createdAt, 'DD MMM YYYY, HH:mm')}
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          color:
                            reward.mode === 'CREDIT'
                              ? Colors.GREEN_VERIFIED
                              : Colors.ORANGE, // Changed to yellow color
                          fontWeight: 'bold',
                        }}
                      >
                        {reward.mode === 'CREDIT' ? '+' : '-'}
                        {reward.xpPoints}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {getRewardTypeLabel(reward.rewardType)}
                    </TableCell>
                    <TableCell>
                      {reward.mode === 'DEBIT'
                        ? 'Redeemed'
                        : getRewardCategoryLabel(reward.rewardCategory)}
                    </TableCell>
                    <TableCell>{reward.metalType}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'inline-block',
                          px: 1.5,
                          py: 0.5,
                          borderRadius: '4px',
                          backgroundColor:
                            reward.mode === 'CREDIT'
                              ? Colors.LIGHT_SUCCESS_BACKGROUND
                              : Colors.BACKGROUND_LIGHT_YELLOW, // Light yellow background
                          color:
                            reward.mode === 'CREDIT'
                              ? Colors.GREEN_VERIFIED
                              : Colors.ORANGE, // Yellow text to match
                        }}
                      >
                        {reward.mode}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="body1">
              {t('No rewards data available')}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
