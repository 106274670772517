import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar-debounce';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../common/constants/Color';
import { ALL, MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import { getCityListing, getCountryList } from '../../api/cityManagement';
import SelectInput from '../../components/select-input/SelectInput';
import useChangeLabelForCountryList from '../../hooks/useChangeLabelForCountryList';
import useGetCountryListStructuredObject from '../../hooks/useGetCountryListStructuredObject';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import { getModulePerm } from '../../utils/helperUtility';

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function CityManagement() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const getStructure = useGetCountryListStructuredObject();
  const currentModulePerm = getModulePerm(MODULE_CONST.CITY_MANAGEMENT);
  const [countryList, setCountryList] = useState([
    { label: t('ALL'), value: ALL },
  ]);
  const changeLabelForCountryList =
    useChangeLabelForCountryList(setCountryList);
  const [listingInfo, setListingInfo] = useState({
    cityListingData: [],
    totalList: 0,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterData, setFilterData] = useState({
    countryId: '',
  });

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(limit);
  const pageCount = Math.ceil(listingInfo.totalList / itemsPerPage);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);

  const handlePageChange = (event, value) => {
    setListingInfo((prev) => ({ ...prev, currentPage: value }));
    getTableData(value);
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  const getListQueryPayload = () => {
    let queryPayload = {};
    if (searchTerm) {
      queryPayload['search_str'] = searchTerm;
    }
    if (!!filterData?.countryId && filterData?.countryId !== ALL) {
      queryPayload['country_id'] = filterData?.countryId;
    }
    return queryPayload;
  };

  useEffect(() => {
    fetchListOfCountry();
  }, []);

  useEffect(() => {
    changeLabelForCountryList();
  }, [i18n?.language]);

  const fetchListOfCountry = async () => {
    setLoading(true);
    try {
      const { data } = await getCountryList();
      const structuredCountryList = getStructure(data.data);
      setCountryList((prev) => [...prev, ...structuredCountryList]);
    } catch (error) {
      console.error('Error fetching country list:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, filterData]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setLimit(newRowsPerPage);
    setCurrentPage(1);
    getTableData(1, newRowsPerPage);
  };

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getCityListing({
        query: queryPayload,
        pageLimit: pageSize,
        pageSkip: page - 1,
      });
      setListingInfo({
        cityListingData: data.data,
        totalList: data.totalCount,
        currentPage: page,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  const handleCityUpdate = (data) => {
    navigate(`${ENDPOINTS.ADD_NEW_CITY}?id=${data?.id}`);
  };

  const ButtonForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={`${Colors.GRAY}`}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleCityUpdate(rowItemData)}
          >
            {t('UPDATE')}
          </Typography>
        </Box>
      </>
    );
  };

  const currentItems = listingInfo.cityListingData.map((city) => ({
    id: city?.id,
    country:
      i18n?.language === APP_LANGUAGE.ARABIC
        ? city?.nameAr || 'N/A'
        : city?.nameEng || 'N/A',
    city: city?.name || 'NA',
    delivery_charge: city?.deliveryCharge || 'NA',
    renderButton: () =>
      currentModulePerm.writeMode && <ButtonForTable rowItemData={city} />,
  }));

  const cityListingColumnConfig = [
    {
      key: 'country',
      title: t('Country'),
      sortButton: false,
      requiredStyle: { width: '33%' },
    },
    {
      key: 'city',
      title: t('City'),
      requiredStyle: { width: '33%' },
    },
    {
      key: 'delivery_charge',
      title: t('DELIVERY_CHARGE'),
      sortButton: false,
      requiredStyle: { width: '33%%' },
    },
    {
      key: 'renderButton',
      title: '',
      isComponent: true,
    },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleFilterSelection = (key, value) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: value,
      };

      return data;
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('CITIES')}
        </Typography>
        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => navigate(ENDPOINTS.ADD_NEW_CITY)}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_NEW_CITY')}
          </Button>
        )}
      </Stack>

      <Card>
        <StyledFilterBox>
          <div className="w-50 d-flex justify-content-start">
            <SelectInput
              placeholder={t('Country')}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={'Status'}
              value={filterData.countryId}
              options={countryList}
              handleSelectChange={(obj) => {
                handleFilterSelection('countryId', obj);
              }}
            />
          </div>
          <SearchBar
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={t('Search')}
            maxlength={MAX_INPUT_LENGTH}
            onClear={handleClearSearch}
          />
        </StyledFilterBox>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={cityListingColumnConfig}
          isCheckBox={false}
          loading={loading}
          showPagination={true}
          pageCount={pageCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalList={listingInfo?.totalList}
          itemsPerPage={itemsPerPage}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onSortButtonClick={() => {}}
          emptyTableText={t('NO_CITY_FOUND')}
        />
      </Card>
    </Container>
  );
}
