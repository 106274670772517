import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';

const LogDetails = ({ open, onClose, data }) => {
  const logDetailsData = data
    ? JSON.parse(data.replace(/^Updated details:\s*/, ''))
    : null;
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Card sx={{ width: 800, maxHeight: '80vh', overflowY: 'auto' }}>
          <CloseIcon
            onClick={onClose}
            sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 10 }}
          />
          <CardHeader title="Details" sx={{ textAlign: 'center' }} />
          <CardContent>
            {logDetailsData ? (
              <List>
                {Object.entries(logDetailsData).map(([key, value]) => (
                  <ListItem key={key}>
                    <ListItemText
                      primary={key}
                      secondary={
                        Array.isArray(value)
                          ? value.join(', ')
                          : value === null
                          ? 'null'
                          : value.toString()
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <p>No log details available</p>
            )}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default LogDetails;
