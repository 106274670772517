import { useEffect, useState, useContext } from 'react';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import Pagination from '../../components/pagination';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../common/constants/Color';
import { ACTION_LABELS, NA } from '../../common/constants/AppConstants';
import {
  configThirdPartyClientListing,
  getThirdPartyClientListing,
} from '../../api/thirdPartyClient';
import { errorToaster, getModulePerm } from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import ClientConfigurationModal from './ClientConfigurationModal';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import { RevealTextToRight } from '../../components/revealText/revealText';

export default function ThirdPartyClientListing() {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const currentModulePerm = getModulePerm(MODULE_CONST.THIRD_PARTY_CLIENTS);
  const [listingInfo, setListingInfo] = useState({
    clientListingData: [],
    totalList: 0,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [actionType, setActionType] = useState(undefined);
  const [ModalMessage, setModalMessage] = useState(undefined);
  const [id, setId] = useState(undefined);
  const [dataForModal, setDataForModal] = useState({});

  // Add state for rows per page
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageCount = Math.ceil(listingInfo.totalList / rowsPerPage);

  const handlePageChange = (event, value) => {
    console.log(`Page changed to: ${value}`);
    setListingInfo((prev) => ({ ...prev, currentPage: value }));
    getTableData(value, rowsPerPage);
  };

  // Add handler for rows per page changes
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    console.log(`Rows per page changed to: ${newRowsPerPage}`);
    setRowsPerPage(newRowsPerPage);
    setListingInfo((prev) => ({ ...prev, currentPage: 1 }));
    getTableData(1, newRowsPerPage);
  };

  useEffect(() => {
    getTableData(listingInfo.currentPage, rowsPerPage);
  }, []);

  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    console.log(`Fetching clients: page=${page}, pageSize=${pageSize}`);
    setLoading(true);
    try {
      const { data } = await getThirdPartyClientListing({
        id: undefined,
        pageLimit: pageSize,
        pageSkip: page - 1,
      });
      setListingInfo((prev) => ({
        ...prev,
        clientListingData: data?.data?.result,
        totalList: Number(data?.data?.totalCount),
        currentPage: page,
      }));
    } catch (error) {
      console.error('Error fetching client data:', error);
      errorToaster(
        error?.data?.message ??
          error?.data ??
          error?.message ??
          t('SOME_ERROR_OCCURED'),
        language
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClientUpdate = (data) => {
    setId(data?.id);
  };

  const ButtonForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={`${Colors.GRAY}`}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => updateExistingClient(rowItemData)}
          >
            {t('UPDATE')}
          </Typography>
        </Box>
      </>
    );
  };

  const currentItems = listingInfo?.clientListingData?.map((client) => ({
    id: client?.id ?? NA,
    name: client?.name ?? NA,
    key: () => <RevealTextToRight text={client?.key} /> || 'N/A',
    status: client?.isActive ? t('ACTIVE') : t('INACTIVE') ?? NA,
    renderButton: () =>
      currentModulePerm.writeMode && <ButtonForTable rowItemData={client} />,
  }));

  const clientListingColumnConfig = [
    {
      key: 'name',
      title: t('Name'),
      sortButton: false,
      requiredStyle: { width: '28%' },
    },
    {
      key: 'key',
      title: t('Key'),
      requiredStyle: { width: '50%' },
      isComponent: true,
    },
    {
      key: 'status',
      title: t('Status'),
      sortButton: false,
      requiredStyle: { width: '12%' },
    },
    {
      key: 'renderButton',
      title: '',
      isComponent: true,
      requiredStyle: { width: '12%' },
    },
  ];

  const resourceName = {
    singular: 'client',
    plural: 'clients',
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsShowModal(false);
  };

  const handleConfirm = async (data) => {
    await configThirdPartyClientListing({ ...{ data } });
  };

  const handleAddClients = () => {
    setIsShowModal(true);
    setActionType(ACTION_LABELS.ADD_NEW);
    setModalMessage(t('ADD_NEW_CLIENT_MSG'));
  };

  const updateExistingClient = (rowItemData) => {
    setIsShowModal(true);
    setDataForModal({
      existingName: rowItemData?.name,
      exisitingKey: rowItemData?.key,
      existingStatus: rowItemData?.isActive,
    });
    setId(rowItemData.id);
    setActionType(ACTION_LABELS.UPDATE_EXISTING);
    setModalMessage(t('UPDATE_EXISTING_CLIENT_MSG'));
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('THIRD_PARTY_CLIENTS')}
        </Typography>
        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={handleAddClients}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_NEW_CLIENT')}
          </Button>
        )}
      </Stack>

      <Card>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={clientListingColumnConfig}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          showPagination={true}
          pageCount={pageCount}
          currentPage={listingInfo.currentPage}
          handlePageChange={handlePageChange}
          totalList={listingInfo.totalList}
          itemsPerPage={rowsPerPage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          emptyTableText={t('NO_CITY_FOUND')}
        />
        {isShowModal && (
          <ClientConfigurationModal
            open={isShowModal}
            handleClose={handleModalClose}
            handleConfirm={handleConfirm}
            actionType={actionType}
            title={
              actionType === ACTION_LABELS.ADD_NEW
                ? t('ADD_NEW_CLIENT')
                : t('UPDATE_THIS_CLIENT')
            }
            reloadTableData={getTableData}
            description={t(ModalMessage)}
            clientId={actionType === ACTION_LABELS.ADD_NEW ? undefined : id}
            {...{ dataForModal }}
            ModalMessage
          />
        )}
      </Card>
    </Container>
  );
}
