// apiEndpoints.js
const API_ENDPOINTS = {
  // Auth related endpoints
  FORGET_PASSWORD: '/auth/forgot-pwd',
  VERIFY_OTP: '/auth/otp-verify',
  RESET_PASSWORD: '/users/reset-pwd',
  RESEND_OTP: '/auth/otp-resend',
  LOGIN: '/auth/login',

  // Role management
  ROLES: '/roles',
  USER_ROLE_LIST: '/roles',
  ADD_ROLE: '/roles',
  GET_ROLE: '/roles',
  DELETE_ROLE: 'roles',

  // User management
  USER: '/users',
  ADMIN_USER_LIST: '/admin/users',
  ADMIN_USER_DETAILS: '/admin/users/',
  ADMINLIST: '/admin/users',
  ADMIN_LOGS: '/admin/logs',
  CREATE_ADMIN: '/auth/admin/users',
  CREATE_ACCOUNT_MANAGER: '/admin/account-managers',
  UPDATE_ADMIN: 'admin/users/',
  USER_GOALS: '/gpm/user-goals',

  // KYC/KYB
  KYC_LIST: '/admin/kyc',
  KYB_LIST: '/mdm/trade-wallets',

  // Masters
  GET_COUNTRIES: '/masters/countries',
  COUNTRIES: 'masters/countries',
  GET_CITY: '/masters/cities',

  // Warehouse
  WAREHOUSE: '/warehouse',
  WAREHOUSES: '/warehouses',

  // Files
  FILES: '/files',

  // Transactions and Wallet
  WALLET_TRANSACTION: '/wallet-transactions',
  TRANSACTION_LIST: '/admin/transaction',
  MANUAL_DEPOSIT: '/wallets/manual',
  WITHDRAWAL_REQUEST: '/wallet/withdrawals',

  // Products and Orders
  CREATE_PRODUCT: '/products',
  PRODUCT_LIST: '/products',
  ORDER_LIST: '/orders',
  BULK_UPDATE: '/products/bulk-update',

  // Reports
  REPORT: '/reports',
  REPORT_TYPES: '/report-types',

  // Account Management
  ACCOUNT_LIST: '/delete-account-requests',
  METAL_TRANSFER: '/metal-transfers',
  DASHBOARD: '/admins/dashboard',

  // Announcements and Support
  CREATE_ANNOUNCEMENT: '/announcements',
  TICKETS_LIST: '/support-tickets',
  UPDATE_TICKET_STATUS: '/support-ticket',

  // Bank Transfers
  BANK_TRANSFER: '/bank-transfers',

  // Gift Settings
  GET_GIFT_SETTINGS: '/gifts',

  // Third Party
  GET_THIRD_PARTY_CLIENT_LISTING: '/clients',

  // Trade Related Endpoints
  GET_TRADE_LIST_OPEN: '/api/mom/trade-positions/open',
  GET_TRADE_LIST_CLOSE: '/api/mom/trade-positions/close',
  GET_TRADE_ORDER_OPEN: '/api/mom/trade-orders/open',
  GET_TRADE_ORDER_CLOSE: '/api/mom/trade-orders/close',
  ADD_TRADE_POSITIONS: '/api/mom/trade-positions',
  CLOSE_TRADE_POSITION: '/api/mom/trade-positions/close',
  UPDATE_TRADE_POSITION: '/api/mom/trade-positions',
  GET_ORDER_DETAILS: '/api/mom/trade-orders',
  UPDATE_TRADE_ORDER: '/api/mom/trade-orders',
  LEASE: '/ulm/lease',

  REWARDS_TRANSACTIONS: 'urm/rewards-transactions',
  // Global Settings
  GLOBAL_SETTINGS: '/global-settings',
};

export default API_ENDPOINTS;
