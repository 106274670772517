import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const setBankDetails = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.GLOBAL_SETTINGS,
    method: METHODS.POST,
    headers: {},
    data: { key: 'BANK_TRANSFER_DETAILS', json_config: data },
    privateURL: true,
  });
};

export const getBankDetails = (query = {}) => {
  if (!query.key) {
    query.key = 'BANK_TRANSFER_DETAILS';
  }
  return apiRequest({
    endpoint: API_ENDPOINTS.GLOBAL_SETTINGS,
    query,
    method: METHODS.GET,
    privateURL: true,
  });
};