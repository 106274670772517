import { Box, Typography, Paper } from '@mui/material';
import { Colors } from '../../common/constants/Color';

export const InformationBox = ({
  title,
  cardInformation,
  singleColumn = false,
  clickableSection = null,
  onSectionClick = null,
}) => {
  const containerStyle = {
    backgroundColor: Colors.WHITE,
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    padding: '16px',
    marginBottom: '16px',
    width: '100%',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.LIGHT_GREY}`,
    paddingBottom: '12px',
    marginBottom: '16px',
  };

  const titleStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.TEXT_PRIMARY,
  };

  const contentStyle = {
    display: 'grid',
    gridTemplateColumns: singleColumn ? '1fr' : '1fr 1fr',
    gap: '16px',
  };

  const infoKeyStyle = {
    fontSize: '14px',
    color: Colors.TEXT_SECONDARY,
    marginBottom: '4px',
  };

  const infoValueStyle = {
    fontSize: '14px',
    fontWeight: '500',
    color: Colors.TEXT_PRIMARY,
  };

  const clickableSectionStyle = {
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: Colors.LIGHT_BACKGROUND,
    },
  };

  return (
    <Paper sx={containerStyle}>
      <Box sx={headerStyle}>
        <Typography sx={titleStyle}>{title}</Typography>
      </Box>
      <Box sx={contentStyle}>
        {Object.entries(cardInformation).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              ...(clickableSection === key && onSectionClick
                ? clickableSectionStyle
                : {}),
              padding: '8px',
              borderRadius: '4px',
            }}
            onClick={() => {
              if (clickableSection === key && onSectionClick) {
                onSectionClick(key);
              }
            }}
          >
            <Typography sx={infoKeyStyle}>{key}</Typography>
            <Typography sx={infoValueStyle}>
              {value}
              {clickableSection === key && onSectionClick && (
                <Box
                  component="span"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    ml: 1,
                    color: Colors.PRIMARY,
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: '12px',
                      fontStyle: 'italic',
                      color: Colors.PRIMARY,
                    }}
                  >
                    (Click to view details)
                  </Box>
                </Box>
              )}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
