/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Card, Stack, Container, Typography, Box } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar';
import { getUserGoals } from '../../api/user';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../components/select-input';
import './userGoalsConstant';
import './UserGoals.css';
import { statusvalue } from './userGoalsConstant';
import { MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';

export default function UserGoalsPage() {
  const { t } = useTranslation();
  const [goalsData, setGoalsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterByStatus, setFilterByStatus] = useState(true);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState({
    value: localStorage.getItem('userGoalsStatus') || 'All',
  });
  const [currentPage, setCurrentPage] = useState(1);

  // Updated page size implementation
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit) || 10);
  const pageCount = Math.ceil(totalList / rowsPerPage);

  // Updated page change handler
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value, rowsPerPage);
  };

  // New handler for rows per page changes
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
    getTableData(1, newRowsPerPage);
  };

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, status]);

  // Updated to include pageSize parameter
  const getTableData = async (page = 1, pageSize = rowsPerPage) => {
    setLoading(true);
    try {
      const query = {
        pageLimit: pageSize,
        pageSkip: page - 1,
        search_str: searchTerm,
      };
      if (status.value !== statusvalue.ALL) {
        if (status.value == statusvalue.active) {
          query.status = 'ACTIVE';
        }
        if (status.value == statusvalue.pending) {
          query.status = 'PENDING';
        }
        if (status.value == statusvalue.cancelled) {
          query.status = 'CANCELLED';
        }
        if (status.value == statusvalue.completed) {
          query.status = 'COMPLETED';
        }
        if (status.value == statusvalue.paused) {
          query.status = 'PAUSED';
        }
      }
      const { data } = await getUserGoals(query);
      setGoalsData(data.data);
      setLimit(data.limit);
      setRowsPerPage(parseInt(data.limit) || rowsPerPage); // Keep rowsPerPage in sync
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  const extractDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  const currentItems = goalsData.map((goal) => ({
    email: goal.email,
    goalName: goal.goalName,
    targetAmount: goal.targetAmount,
    status: goal.status,
    scheduleFrequency: goal.scheduleFrequency,
    startDate: extractDate(goal.startDate),
    scheduleAmount: goal.scheduleAmount,
    metalType: goal.metalType,
    achievedMetalWeight: goal.achievedMetalWeight,
    achievedAmount: goal.achievedAmount,
    completionPercentage: goal.completionPercentage,
  }));

  const rowColumns = [
    {
      key: 'email',
      title: t('EMAIL_TEXT'),
      sortButton: false,
      content: (rowData) => <strong>rowData.email</strong>,
    },
    {
      key: 'goalName',
      title: t('TH_GOALS_NAME'),
      content: (rowData) => <strong>{rowData.goalName}</strong>,
      sortButton: false,
    },
    {
      key: 'targetAmount',
      title: t('TH_TARGET_AMOUNT'),
      sortButton: false,
      content: (rowData) => <strong>rowData.targetAmount</strong>,
    },
    {
      key: 'status',
      title: t('TH_STATUS'),
      sortButton: true,
      content: (rowData) => <strong>rowData.status</strong>,
    },
    {
      key: 'scheduleFrequency',
      title: t('TH_SCHEDULE_FREQUENCY'),
      sortButton: false,
      content: (rowData) => rowData.scheduleFrequency,
    },
    {
      key: 'startDate',
      title: t('TH_START_DATE'),
      sortButton: false,
      content: (rowData) => rowData.startDate,
    },
    {
      key: 'scheduleAmount',
      title: t('TH_SCHEDULE_AMOUNT'),
      sortButton: false,
      content: (rowData) => rowData.scheduleAmount,
    },
    {
      key: 'metalType',
      title: t('TH_METAL_TYPE'),
      sortButton: false,
      content: (rowData) => <strong>rowData.metalType</strong>,
    },
    {
      key: 'achievedMetalWeight',
      title: t('TH_ACHIEVED_METAL_WEIGHT'),
      sortButton: false,
      content: (rowData) => <strong>rowData.achievedMetalWeight</strong>,
    },
    {
      key: 'achievedAmount',
      title: t('TH_ACHIEVED_AMOUNT'),
      sortButton: false,
      content: (rowData) => <strong>rowData.achievedAmount</strong>,
    },
    {
      key: 'completionPercentage',
      title: t('TH_COMPLETION_PERCENTAGE'),
      sortButton: false,
      content: (rowData) => <strong>rowData.completionPercentage</strong>,
    },
  ];

  const resourceName = {
    singular: 'goal',
    plural: 'goals',
  };

  useEffect(() => {
    const savedStatus = localStorage.getItem('userGoalsStatus');
    if (savedStatus) {
      setStatus({ value: savedStatus });
    }
  }, []);

  const handleFilterSelection = (key, event) => {
    const selectedStatus = event;
    setStatus({
      value: selectedStatus,
    });
    localStorage.setItem('userGoalsStatus', selectedStatus);
  };
  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('USER_GOALS')}
        </Typography>
      </Stack>

      <Card>
        <Scrollbar>
          <div className="filter-wrapper">
            <SelectInput
              placeholder={t('Status')}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={t('Status')}
              value={status.value}
              options={[
                { label: t('ALL'), value: 'All' },
                { label: t('ACTIVE'), value: 'active' },
                { label: t('Pending'), value: 'pending' },
                { label: t('Cancelled'), value: 'cancelled' },
                { label: t('Completed'), value: 'completed' },
                { label: t('PAUSED'), value: 'paused' },
              ]}
              handleSelectChange={(event) => {
                handleFilterSelection('status', event);
              }}
            />
            <SearchBar
              onChange={handleSearchChange}
              value={searchTerm}
              placeholder={t('Search')}
              maxlength={MAX_INPUT_LENGTH}
              onClear={handleClearSearch}
            />
          </div>
          <CommonTable
            resourceName={resourceName}
            data={currentItems}
            columns={rowColumns}
            isCheckBox={false}
            loading={loading}
            onSortButtonClick={() => {}}
            handleStatusChange={handleStatusChange}
            emptyTableText={t('NO_USER_GOALS_FOUND')}
            showPagination={true}
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalList={totalList}
            itemsPerPage={rowsPerPage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Scrollbar>
      </Card>
    </Container>
  );
}
