const ENDPOINTS = {
  BANK_TRANSFERS: '/dashboard/bank-transfers',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: 'reset-password',
  OPT_VERIFICATION: 'verify-otp',
  LOGIN: 'login',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/dashboard/user',
  ROLE_MANAGEMENT: '/dashboard/role',
  ROLE_FORM: '/dashboard/role-form',
  ADD_USER: 'add-user',
  ADMIN_MANAGEMENT: '/dashboard/admin',
  ADMIN_FORM: '/dashboard/admin-form',
  VIEW_USER: 'view-user',
  EDIT_USER: 'edit-user',
  KYC_MANAGEMENT: '/dashboard/kyc',
  KYB_MANAGEMENT: '/dashboard/kyb',
  WAREHOUSE_MANAGEMENT: '/dashboard/warehouse',
  TRANSACTION_MANAGEMENT: '/dashboard/transaction',
  WALLET_TRANSACTION_DETAILS: '/dashboard/transaction-details',
  WAREHOUSE: 'warehouse',
  ADD: 'add',
  FORM: 'form',
  VIEW_WAREHOUSE: 'view',
  KYC_VERIFICATION: '/dashboard/kyc-verification',
  KYB_VERIFICATION: '/dashboard/kyb-verification',
  PRODUCT_MANAGEMENT: '/dashboard/products',
  ORDER_MANAGEMENT: '/dashboard/orders',
  REPORT_MANAGEMENT: '/dashboard/reports',
  ADMIN_LOGS: '/dashboard/logs',
  PRODUCTS: '/dashboard/products',
  Page404: '/404',
  ADDPRODUCT: '/dashboard/add-product',
  EDITPRODUCT: '/dashboard/edit-product',
  ORDER_DETAILS: '/dashboard/order-details',
  REPORT: 'reports',
  ACCOUNT_LIST: '/dashboard/delete-account',
  ACCOUNT_DELETE_DETAILS: '/dashboard/delete-account/details',
  TRANSFER_LIST: '/dashboard/transfer-gold-silver',
  TRANSFER_LIST_DETAILS: '/dashboard/transfer-gold-silver/details',
  HOME: '/dashboard/app',
  ACCOUNT_MANAGEMENT: '/dashboard/account-manager',
  ACCOUNT_MANAGER_FORM: '/dashboard/account-manager-form',
  BANK_DETAILS: '/dashboard/bank-details',
  ANNOUNCEMENT_LIST: '/dashboard/announcement',
  ANNOUNCEMENT_FORM: '/dashboard/announcement-form',
  TICKET_MANAGEMENT: '/dashboard/ticket-management',
  TICKET_MANAGEMENT_DETAILS: '/dashboard/ticket-management/details',
  CITY_MANAGEMENT: '/dashboard/city-management',
  ADD_NEW_CITY: '/dashboard/add-new-city',
  WITHDRAWALS: '/dashboard/withdrawals',
  WITHDRAWAL_DETAILS: '/dashboard/withdraw/details',
  GIFT_SETTINGS: '/dashboard/gift-settings',
  ADD_NEW_SETTINGS: '/dashboard/add-new-settings',
  UPDATE_SETTINGS: '/dashboard/update-setting',
  THIRD_PARTY_CLIENTS_LISTING: '/dashboard/third-party-clients',
  TRADE_LIST: '/dashboard/trade-list',
  TRADE_ORDER: '/dashboard/trade-orders',
  TRADE_LIST_DETAILS: '/dashboard/trade-list-details',
  TRADE_ORDER_VIEW: '/dashboard/trade-order-details',
  TRADE_EDIT: '/dashboard/trade-list/edit/:id',
  USER_GOALS: '/dashboard/goals',
  LEASE: '/dashboard/lease',
};

export default ENDPOINTS;
